import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconActor(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.fillStyle = theme.palette.primary;
  ctx.translate(-6, -6);
  ctx.beginPath();
  ctx.moveTo(18, 4.9375);
  ctx.bezierCurveTo(25.457031, 4.9375, 31.5, 10.902344, 31.5, 18.257812);
  ctx.bezierCurveTo(31.5, 25.613281, 25.457031, 31.578125, 18, 31.578125);
  ctx.bezierCurveTo(10.542969, 31.578125, 4.5, 25.613281, 4.5, 18.257812);
  ctx.bezierCurveTo(4.5, 10.902344, 10.542969, 4.9375, 18, 4.9375);
  ctx.closePath();
  ctx.moveTo(18, 7.898438);
  ctx.bezierCurveTo(12.199219, 7.898438, 7.5, 12.535156, 7.5, 18.257812);
  ctx.bezierCurveTo(7.5, 23.980469, 12.199219, 28.617188, 18, 28.617188);
  ctx.bezierCurveTo(23.800781, 28.617188, 28.5, 23.980469, 28.5, 18.257812);
  ctx.bezierCurveTo(28.5, 12.535156, 23.800781, 7.898438, 18, 7.898438);
  ctx.closePath();
  ctx.moveTo(18, 12.335938);
  ctx.bezierCurveTo(21.3125, 12.335938, 24, 14.988281, 24, 18.257812);
  ctx.bezierCurveTo(24, 21.527344, 21.3125, 24.175781, 18, 24.175781);
  ctx.bezierCurveTo(14.6875, 24.175781, 12, 21.527344, 12, 18.257812);
  ctx.bezierCurveTo(12, 14.988281, 14.6875, 12.335938, 18, 12.335938);
  ctx.closePath();
  ctx.moveTo(18, 12.335938);
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
}

export default SVGIconActor;
