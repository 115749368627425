import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconAccount(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.fillStyle = theme.palette.primary;
  ctx.translate(-6, -6);
  ctx.beginPath();
  ctx.moveTo(7.503906, 6.417969);
  ctx.bezierCurveTo(5.84375, 6.417969, 4.503906, 7.742188, 4.503906, 9.378906);
  ctx.bezierCurveTo(
    4.503906,
    11.011719,
    5.84375,
    12.335938,
    7.503906,
    12.335938
  );
  ctx.lineTo(27.003906, 12.335938);
  ctx.lineTo(27.003906, 11.152344);
  ctx.bezierCurveTo(
    27.003906,
    9.496094,
    27.003906,
    8.667969,
    26.675781,
    8.035156
  );
  ctx.bezierCurveTo(
    26.386719,
    7.476562,
    25.929688,
    7.023438,
    25.363281,
    6.738281
  );
  ctx.bezierCurveTo(
    24.722656,
    6.417969,
    23.882812,
    6.417969,
    22.203125,
    6.417969
  );
  ctx.closePath();
  ctx.moveTo(4.503906, 12.335938);
  ctx.lineTo(4.503906, 25.359375);
  ctx.bezierCurveTo(
    4.503906,
    27.019531,
    4.503906,
    27.847656,
    4.828125,
    28.480469
  );
  ctx.bezierCurveTo(
    5.117188,
    29.039062,
    5.574219,
    29.492188,
    6.140625,
    29.773438
  );
  ctx.bezierCurveTo(
    6.78125,
    30.097656,
    7.621094,
    30.097656,
    9.300781,
    30.097656
  );
  ctx.lineTo(26.703125, 30.097656);
  ctx.bezierCurveTo(
    28.382812,
    30.097656,
    29.222656,
    30.097656,
    29.863281,
    29.773438
  );
  ctx.bezierCurveTo(
    30.429688,
    29.492188,
    30.886719,
    29.039062,
    31.175781,
    28.480469
  );
  ctx.bezierCurveTo(
    31.503906,
    27.847656,
    31.503906,
    27.019531,
    31.503906,
    25.359375
  );
  ctx.lineTo(31.503906, 18.554688);
  ctx.bezierCurveTo(
    31.503906,
    16.894531,
    31.503906,
    16.066406,
    31.175781,
    15.433594
  );
  ctx.bezierCurveTo(
    30.886719,
    14.875,
    30.429688,
    14.421875,
    29.863281,
    14.140625
  );
  ctx.bezierCurveTo(
    29.222656,
    13.816406,
    28.382812,
    13.816406,
    26.703125,
    13.816406
  );
  ctx.lineTo(7.5, 13.816406);
  ctx.bezierCurveTo(
    6.273438,
    13.816406,
    5.1875,
    13.234375,
    4.503906,
    12.335938
  );
  ctx.closePath();
  ctx.moveTo(27.003906, 21.957031);
  ctx.bezierCurveTo(27.003906, 24, 25.324219, 25.65625, 23.253906, 25.65625);
  ctx.bezierCurveTo(21.179688, 25.65625, 19.503906, 24, 19.503906, 21.957031);
  ctx.bezierCurveTo(
    19.503906,
    19.914062,
    21.179688,
    18.257812,
    23.253906,
    18.257812
  );
  ctx.bezierCurveTo(
    25.324219,
    18.257812,
    27.003906,
    19.914062,
    27.003906,
    21.957031
  );
  ctx.closePath();
  ctx.moveTo(23.253906, 24.175781);
  ctx.bezierCurveTo(
    24.496094,
    24.175781,
    25.503906,
    23.183594,
    25.503906,
    21.957031
  );
  ctx.bezierCurveTo(
    25.503906,
    20.730469,
    24.496094,
    19.738281,
    23.253906,
    19.738281
  );
  ctx.bezierCurveTo(
    22.011719,
    19.738281,
    21.003906,
    20.730469,
    21.003906,
    21.957031
  );
  ctx.bezierCurveTo(
    21.003906,
    23.183594,
    22.011719,
    24.175781,
    23.253906,
    24.175781
  );
  ctx.closePath();
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
}

export default SVGIconAccount;
