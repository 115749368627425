import { call, put, select, takeEvery } from 'redux-saga/effects';
import AppUtils from '@control-front-end/utils/utils';
import api from '@control-front-end/common/sagas/api';
import {
  GET_EDGE,
  CREATE_EDGE,
  CREATE_MASS_EDGES,
  GET_EDGE_TYPE,
  GET_EDGE_TYPES,
  REMOVE_EDGE,
  REMOVE_NODES_EDGE,
  UPDATE_ACTOR_VIEW,
  UPDATE_EDGE,
} from '@control-front-end/common/constants/graphActors';
import { RequestStatus } from 'constants';
import apiBulk from '@control-front-end/common/sagas/apiBulk';
import {
  getGraphEls,
  sendReducerMsg,
  getActorByGraphId,
  getActiveLayer,
  updateListElements,
} from './graphHelpers';
import { manageLayerElements } from '../../routes/ActorsGraph/sagas/layers/layerElements';
import { updateActorView } from '../actorView';

/**
 * Получить связь между акторами
 */
function* getEdge({ payload, callback }) {
  const { id: edgeId, actorView = false, linkedActor = false } = payload;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/actors/link/${edgeId}?linkedActor=${linkedActor}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  if (actorView) {
    yield put({
      type: UPDATE_ACTOR_VIEW.SUCCESS,
      payload: data.data,
    });
  }
  yield put({ type: GET_EDGE.SUCCESS, payload: data.data });
  if (callback) callback(data.data);
}

/**
 * Создать связь между акторами
 */
function* createEdge({ payload, callback }) {
  const {
    source,
    target,
    laIdSource,
    laIdTarget,
    edgeTypeId,
    name,
    manageLayer = true,
    ignoreLayerFunc = false,
    subscribeBalances = true,
  } = payload;
  const accounts = yield select((state) => state.accounts);
  const accId = accounts.active;
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/actors/link/${accId}`,
    body: { name, source, target, edgeTypeId },
  });
  if (result !== RequestStatus.SUCCESS) return;
  const edge = data.data;
  edge.laIdSource = laIdSource;
  edge.laIdTarget = laIdTarget;
  const activeLayer = yield getActiveLayer();
  if (activeLayer && !ignoreLayerFunc) {
    yield call(manageLayerElements, {
      payload: {
        layerId: activeLayer.id,
        body: [
          {
            action: 'create',
            data: {
              id: edge.id,
              laIdSource: edge.laIdSource,
              laIdTarget: edge.laIdTarget,
              model: edge,
              type: 'edge',
            },
          },
        ],
        withReq: manageLayer && activeLayer.typeLayer === 'layers',
        subscribeBalances,
      },
    });
  }
  yield put({ type: CREATE_EDGE.SUCCESS });
  if (callback) callback(edge);
}

/**
 * Создать массово связи между акторами
 */
export function* createMassEdges({ payload, callback }) {
  const { edges, subscribeBalances = true, withEdges = true } = payload;
  const accounts = yield select((state) => state.accounts);
  const accId = accounts.active;
  const responses = yield call(apiBulk, {
    method: 'post',
    url: `/actors/mass_links/${accId}`,
    body: edges,
  });
  yield put({ type: CREATE_MASS_EDGES.SUCCESS });
  const activeLayer = yield getActiveLayer();
  if (!activeLayer || !withEdges) {
    callback?.();
    return;
  }
  const newEdges = responses.reduce((acc, i) => acc.concat(...i.data.data), []);
  const body = [];
  newEdges.forEach((i, index) => {
    if (i.error) return;
    i.data.laIdSource = edges[index].laIdSource;
    i.data.laIdTarget = edges[index].laIdTarget;
    body.push({
      action: 'create',
      data: {
        id: i.data.id,
        laIdSource: i.data.laIdSource,
        laIdTarget: i.data.laIdTarget,
        model: i.data,
        type: 'edge',
      },
    });
  });
  yield call(manageLayerElements, {
    payload: {
      layerId: activeLayer.id,
      body,
      withReq: true,
      subscribeBalances,
    },
  });
  callback?.();
}

/**
 * Обновить связь между акторами
 */
function* updateEdge({ payload, callback }) {
  const { id, name, linkedActorId } = payload;
  const edgeModel = yield getActorByGraphId(id, 'edges');
  const { result, data } = yield call(api, {
    method: 'put',
    url: `/actors/link/${edgeModel.edgeId}`,
    body: { name, linkedActorId },
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield call(updateActorView, {
    payload: {
      actorData: {
        id,
        name,
        linkedActorId,
        linkedActor: data.data.linkedActor,
      },
    },
  });
  const copyEdges = yield call(getGraphEls, 'edges');
  updateListElements({
    list: copyEdges,
    update: {
      status: 'updated',
      data: {
        edgeId: id,
        name,
        linkedActorId,
        linkedActor: data.data.linkedActor,
      },
    },
    key: 'data.edgeId',
  });
  yield sendReducerMsg({
    type: UPDATE_EDGE.SUCCESS,
    payload: { edges: copyEdges },
  });
  if (callback) callback(data.data);
}

/**
 * Remove edge
 */
export function* removeEdge({ payload, callback }) {
  const { id, withReq, manageLayer } = payload;
  // get edge with all its duplicates on layer
  const edgeModels = yield getActorByGraphId(id, 'edges', AppUtils.isUuid(id));
  const edgeWithDoubles = Array.isArray(edgeModels) ? edgeModels : [edgeModels];
  if (!edgeWithDoubles.length) return;
  if (withReq) {
    const { result } = yield call(api, {
      method: 'delete',
      url: `/actors/link/${edgeWithDoubles[0].edgeId}`,
    });
    if (result !== RequestStatus.SUCCESS) return;
  }
  const activeLayer = yield getActiveLayer();
  if (activeLayer) {
    yield call(manageLayerElements, {
      payload: {
        layerId: activeLayer.id,
        body: edgeWithDoubles.map((edge) => ({
          action: 'delete',
          data: {
            id: edge.id,
            laIdSource: edge.laIdSource,
            laIdTarget: edge.laIdTarget,
            model: edge,
            type: 'edge',
          },
        })),
        withReq: manageLayer,
      },
    });
  }
  if (callback) callback();
}

/**
 * Удалить связь между нодами
 */
function* removeNodesEdge({ payload, callback }) {
  const { source, target, edgeTypeId } = payload;
  const { result } = yield call(api, {
    method: 'delete',
    url: '/actors_graph/actors_link',
    body: { source, target, edgeTypeId },
  });
  if (result !== RequestStatus.SUCCESS) return;
  if (callback) callback();
}

/**
 * Получить все типы связей
 */
function* getEdgeTypes({ callback }) {
  const accounts = yield select((state) => state.accounts);
  const accId = accounts.active;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/edge_types/${accId}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield put({
    type: GET_EDGE_TYPES.SUCCESS,
    payload: data.data,
  });
  if (callback) callback(data.data);
}

/**
 * Получить тип связи по имени
 */
function* getEdgeType({ payload, callback }) {
  const { name, isTree } = payload;
  const edgeTypes = yield select((state) => state.edgeTypes);
  const findEdgeType = edgeTypes.find((i) => i.name === name);
  if (findEdgeType) {
    callback(findEdgeType);
    return;
  }
  const accounts = yield select((state) => state.accounts);
  const accId = accounts.active;
  const { result, data } = yield call(api, {
    method: 'get',
    url: `/edge_types/${accId}/${name}`,
    queryParams: { createIfNotExist: true, isTree },
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield put({
    type: GET_EDGE_TYPE.SUCCESS,
    payload: data.data,
  });
  if (callback) callback(data.data);
}

function* graphEdges() {
  yield takeEvery(GET_EDGE.REQUEST, getEdge);
  yield takeEvery(CREATE_EDGE.REQUEST, createEdge);
  yield takeEvery(CREATE_MASS_EDGES.REQUEST, createMassEdges);
  yield takeEvery(UPDATE_EDGE.REQUEST, updateEdge);
  yield takeEvery(REMOVE_EDGE.REQUEST, removeEdge);
  yield takeEvery(REMOVE_NODES_EDGE.REQUEST, removeNodesEdge);
  yield takeEvery(GET_EDGE_TYPES.REQUEST, getEdgeTypes);
  yield takeEvery(GET_EDGE_TYPE.REQUEST, getEdgeType);
}

export default graphEdges;
