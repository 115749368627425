import {
  call,
  select,
  put,
  delay,
  takeEvery,
  takeLatest,
} from 'redux-saga/effects';
import { keyBy, isMatch, pickBy, isEmpty } from 'lodash';

import {
  SAVE_ACTOR_LAYER_SETTINGS,
  EDITABLE_NODE,
  EXPANDED_LAYER_LOADED,
  MANAGE_LAYER_ACTORS,
  SAVE_LAYER_POSITION,
  STARRED_LAYER_ACTOR,
  GET_VERTICAL_LINKS,
  SET_VERTICAL_LINKS,
  TOGGLE_ACTORS_LAYER_NUMBERS,
  TOGGLE_NODES_COORDINATES,
  SET_MAX_LAYER_NUMBER,
} from '@control-front-end/common/constants/graphLayers';
import {
  DELAY_BEFORE_NEXT_GRAPH_UPDATE,
  STATE_BACKGROUND_OPACITY,
  WS_CREATE_EDGE,
} from '@control-front-end/common/constants/graphActors';
import { PM_APP_NAME, RequestStatus } from 'constants';
import waitFor from '@control-front-end/common/sagas/waitFor';
import api from '@control-front-end/common/sagas/api';
import AppUtils from '@control-front-end/utils/utils';
import { Utils } from 'mw-style-react';
import {
  getGraphEls,
  getLayerById,
  makeGraphModels,
  sendReducerMsg,
  updateActorProp,
  updateListElements,
  makeActorPictureBase,
} from '../../../../sagas/graph/graphHelpers';
import {
  getActiveLayer,
  saveNodePositions,
  inheritGraphLayerAccounts,
} from './layerHelpers';
import {
  getNewBalances,
  resubscribeBalances,
} from '../../../../sagas/graph/graphRealtime';

/**
 * Сделать редактируемым узел на слое
 */
function* editableNode({ payload }) {
  yield sendReducerMsg({
    type: EDITABLE_NODE.SUCCESS,
    payload: {
      editableElement: payload,
    },
  });
}

/**
 * Отметить актор на слое
 */
function* starredActor({ payload }) {
  const { layerId, id, laId, starred } = payload;
  const { result } = yield call(api, {
    method: 'put',
    url: `/graph_layers/starred/${laId}/${starred}`,
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield updateActorProp({
    type: STARRED_LAYER_ACTOR.SUCCESS,
    layerId,
    id,
    propId: 'layerStarred',
    value: starred,
  });
}

/**
 * Сохранить позиции узлов на графе
 */
function* saveLayerPosition({ payload, callback, errorCallback }) {
  const { layerId, positions = [], manageLayer } = payload;
  const graphL = yield select((state) => state.graphLayers);
  const layer = graphL.list.find((i) => i.id === layerId);
  if (!layer) return;
  if (manageLayer && layer.typeLayer === 'layers') {
    const nodesMap = keyBy(layer.nodes, 'data.laId');
    const filteredNodes = positions.filter(
      (i) => nodesMap[i.id] && !isMatch(i.position, nodesMap[i.id].position)
    );

    if (!filteredNodes.length) {
      callback?.();
      return;
    }
    const { result } = yield call(api, {
      method: 'put',
      url: `/graph_layers/actors/${layerId}`,
      body: filteredNodes,
    });
    if (result !== RequestStatus.SUCCESS) {
      errorCallback?.();
      return;
    }
  }
  const copyNodes = yield call(saveNodePositions, { layerId, positions });
  yield sendReducerMsg({
    layerId,
    type: SAVE_LAYER_POSITION.SUCCESS,
    payload: {
      nodes: copyNodes,
      edges: yield call(getGraphEls, 'edges', layerId),
    },
  });
  if (callback) callback();
}

/**
 * Create layer change request objects.
 * For each body item sets related data, depending on item type (that could be a node or an edge)
 */
function createManageLayerBody(body) {
  return body
    .filter(({ data }) => !data.model.isTrace)
    .map(({ action, data }) => {
      const { type, id, position, areaPicture, polygon, model } = data;
      const obj = { action };
      if (type === 'node') {
        obj.data = {
          id: model.actorId || id,
          type,
          laId: model.laId,
          position,
          areaPicture,
        };
        if (polygon) obj.data.polygon = polygon;
      } else {
        obj.data = {
          id: model && model.edgeId ? model.edgeId : id,
          type,
          laId: action === 'create' ? data.laId : model.laId,
          laIdSource: data.laIdSource,
          laIdTarget: data.laIdTarget,
        };
      }
      return obj;
    });
}

/**
 * Обновить балансы узлов на слое при обновлении
 */
function* updateBalances({
  layerId,
  nodes,
  reSub = true,
  subscribeBalances = true,
}) {
  const uniqueActorsIds = new Set(nodes.map((i) => i.data.actorId));
  if (subscribeBalances) {
    yield getNewBalances({ layerId, actorIds: [...uniqueActorsIds] });
  }
  if (reSub) yield resubscribeBalances({ layerId });
}

/**
 * Добавить элементы на слой
 */
function addElements({ elements, nodesMap, edgesMap }) {
  const addedNodes = [];
  const addedEdges = [];
  const mapNodeIds = {};
  let nodeIndex = 0;
  let edgeIndex = 0;
  for (const { data } of elements) {
    const { id, type, model } = data;
    const map = type === 'node' ? nodesMap : edgesMap;
    const index = type === 'node' ? nodeIndex : edgeIndex;
    const mapEl = map[index] || {};
    if (type === 'node') {
      addedNodes.push({ ...model, laId: mapEl.laId });
      mapNodeIds[id] = mapEl.laId ? `node_${mapEl.laId}` : id;
      nodeIndex += 1;
    } else if (type === 'edge') {
      addedEdges.push({ ...model, ...mapEl });
      edgeIndex += 1;
    }
  }
  return { addedNodes, addedEdges, mapNodeIds };
}

/**
 * Удалить элементы со слоя
 */
function removeElements({ elements, copyNodes, copyEdges }) {
  for (const { data } of elements) {
    const { type, model, withDuplicates } = data;
    updateListElements({
      list: type === 'node' ? copyNodes : copyEdges,
      update: { status: 'removed', data: model },
      key: withDuplicates
        ? `data.${type === 'node' ? 'actorId' : 'edgeId'}`
        : 'data.id',
    });
  }
}

/**
 * Обновить элементы со слоя
 */
function updateElements({ elements, copyNodes, copyEdges, config }) {
  for (const { data } of elements) {
    const { type, model } = data;
    model.pictureUrl = makeActorPictureBase(model, config);
    updateListElements({
      list: type === 'node' ? copyNodes : copyEdges,
      update: { status: 'updated', data: model },
      key: model.isStaticLayerUpdatableNode
        ? 'data.id'
        : `data.${type === 'node' ? 'actorId' : 'edgeId'}`,
    });
  }
}

/**
 * Получить связи удаляемых узлов
 */
function getLinkedRemovedEdges({ body, copyEdges }) {
  const deletedEdges = [];
  for (const { action, data } of body) {
    if (action !== 'delete' || data.type === 'edge') continue;
    for (const e of copyEdges) {
      const { edgeId, source, target, status, switchBox } = e.data;
      if (
        (source === data.model.id || target === data.model.id) &&
        status !== 'removed' &&
        !switchBox
      ) {
        deletedEdges.push({
          action: 'delete',
          data: { id: edgeId, model: e.data, type: 'edge' },
        });
      }
    }
  }
  return deletedEdges;
}

/**
 * Добавить акторы в слой
 */
export function* manageLayerElements({ payload, callback }) {
  const {
    layerId,
    body,
    activeElement,
    activeType,
    editableElement,
    withReq = true,
    subscribeBalances = true,
    reduxEvent = true,
  } = payload;
  if (!layerId || !body?.length) return;
  const config = yield select((state) => state.config);
  // Used separate copy to prevent race conditions, fresher state copies go after the requests
  const copyEdgesTmp = yield call(getGraphEls, 'edges', layerId);
  const removedEdges = getLinkedRemovedEdges({ body, copyEdges: copyEdgesTmp });
  body.push(...removedEdges);
  let nodesMap = [];
  let edgesMap = [];
  if (withReq) {
    const manageLayerBody = createManageLayerBody(body);
    const { result, data: resp } = yield call(api, {
      method: 'post',
      url: `/graph_layers/actors/${layerId}`,
      body: manageLayerBody,
      handleErrCodes: [400],
    });
    if (result !== RequestStatus.SUCCESS) {
      return { nodesMap: [], edgesMap: [], errorMessage: resp.message };
    }
    nodesMap = resp.data.nodesMap;
    edgesMap = resp.data.edgesMap;
    yield inheritGraphLayerAccounts(manageLayerBody);
  }
  const layerModel = yield getLayerById(layerId);
  if (!layerModel) {
    return { nodesMap, edgesMap };
  }

  const copyNodes = yield call(getGraphEls, 'nodes', layerId);
  const copyEdges = yield call(getGraphEls, 'edges', layerId);

  // Get new graph nodes is there is some
  const { addedNodes, addedEdges, mapNodeIds } = addElements({
    elements: body.filter((i) => i.action === 'create'),
    nodesMap,
    edgesMap,
  });

  // Mark nodes with status 'removed' in graph format if there is some to delete
  removeElements({
    elements: body.filter((i) => i.action === 'delete'),
    copyNodes,
    copyEdges,
  });

  // Mark nodes with status 'updated' in graph format if there is some to update
  updateElements({
    elements: body.filter((i) => i.action === 'update'),
    copyNodes,
    copyEdges,
    config,
  });

  const { nodes, edges } = yield call(makeGraphModels, {
    nodes: addedNodes,
    edges: addedEdges,
    isTree: layerModel.type === 'tree',
  });

  copyNodes.push(...nodes);
  copyEdges.push(...edges);
  if (reduxEvent) {
    yield sendReducerMsg({
      type: MANAGE_LAYER_ACTORS.SUCCESS,
      payload: {
        // use structuredClone to avoid "read-only property" errors in Cytoscape
        nodes: structuredClone(copyNodes),
        edges: structuredClone(copyEdges),
        activeElement: mapNodeIds[activeElement] || layerModel.activeElement,
        activeType,
        editableElement:
          mapNodeIds[editableElement] || layerModel.editableElement,
      },
      layerId,
    });
  }
  const isLayerCommenting =
    addedNodes.length === 1 &&
    addedNodes[0].data &&
    addedNodes[0].data.channel === 'layerArea';
  /**
   * Currently there is no need to know balances for static layers
   * WARNING: It doesn't work correctly if there were actorId changes on the nodes
   */
  if (!isLayerCommenting && !layerModel.isStatic) {
    yield delay(DELAY_BEFORE_NEXT_GRAPH_UPDATE);
    yield updateBalances({
      layerId,
      nodes: copyNodes,
      reSub: !!addedNodes.length,
      subscribeBalances,
    });
  }

  callback?.();

  return { nodesMap, edgesMap, addedNodes, addedEdges };
}

/**
 * Save actors settings on layer
 */
function* saveActorLayerSettings({ payload, callback }) {
  const { id, settings, polygon } = payload;
  const activeLayer = yield getActiveLayer();
  const node = activeLayer.nodes.find((i) => i.id === id);

  const updatedSettings = {
    ...(node.data.layerSettings || {}),
    ...settings,
  };

  if (activeLayer.typeLayer === 'layers') {
    const { profile, ...layerSettings } = updatedSettings;
    const { result } = yield call(api, {
      method: 'put',
      url: `/graph_layers/actor_settings/${id.replace('node_', '')}`,
      body: pickBy(
        {
          layerSettings: isEmpty(layerSettings) ? undefined : layerSettings,
          polygon,
        },
        (i) => i !== undefined
      ),
    });
    if (result !== RequestStatus.SUCCESS) return;
  }
  const updates = polygon
    ? {
        polygon,
        areaPicture: AppUtils.createImgByPolygon({
          polygon,
          color: node.data.color,
          opacity: STATE_BACKGROUND_OPACITY,
        }),
      }
    : {
        layerSettings: updatedSettings,
      };
  const copyNodes = yield call(getGraphEls, 'nodes', activeLayer.id);
  updateListElements({
    list: copyNodes,
    update: {
      status: 'updated',
      data: {
        ...node.data,
        ...updates,
      },
    },
    key: 'data.id',
  });
  yield sendReducerMsg({
    layerId: activeLayer.id,
    type: SAVE_ACTOR_LAYER_SETTINGS.SUCCESS,
    payload: { nodes: copyNodes },
  });
  callback?.();
}

const MIN_LAYERS_AMOUNT_FOR_VERTICAL_LINKS = 2;

/**
 * Получить вертикальные связи между слоями
 */
export function* getVerticalLinks({ payload }) {
  const { layers } = payload;
  const ws = yield select((state) => state.accounts);
  const { result, data } = yield call(api, {
    method: 'post',
    url: `/layers_links/vertical_links/${ws.active}`,
    body: { layers },
  });
  if (result !== RequestStatus.SUCCESS) return;
  yield put({
    type: GET_VERTICAL_LINKS.SUCCESS,
    payload: data.data,
  });
  return data.data;
}

/**
 * Set vertical links between layerss
 */
function* setVerticalLinks({ payload }) {
  const { verticalLinks } = payload;
  yield call(waitFor, (state) => state.graphLayers.list.length > 0);
  const layer = yield select((state) => state.graphLayers.list[0]);
  yield delay(DELAY_BEFORE_NEXT_GRAPH_UPDATE);
  const copyNodes = yield call(getGraphEls, 'nodes', layer.id);
  const copyEdges = yield call(getGraphEls, 'edges', layer.id);
  if (!copyNodes.length) return;
  const id = 'switchBox';
  const switchNodes = [];
  const switchEdges = [];
  const findSwitchBox = copyNodes.find((i) => i.id === id);
  if (!findSwitchBox) {
    switchNodes.push({
      id,
      classes: ['switchBox'],
      readOnly: true,
      noContextMenu: true,
      data: {},
    });
  }
  // Формируем список узлов "щитка" и связей с ним
  for (const l of verticalLinks) {
    if (l.source.id !== layer.id && l.target.id !== layer.id) continue;
    const switchItem = l.source.id === layer.id ? l.target : l.source;
    const findExistItem = copyNodes.find((i) => i.id === switchItem.id);
    if (findExistItem) continue;
    // Корректируем вертикальные ссылки, чтобы они шли через "щиток"
    const uniqueEdges = [];
    for (const edge of l.edges) {
      const findS = layer.nodes.find((i) => i.data.actorId === edge.source);
      const findT = layer.nodes.find((i) => i.data.actorId === edge.target);
      edge.id = `${edge.id}_${l.source.id}_${l.target.id}`;
      edge.switchBox = true;
      edge.realSource = edge.source;
      edge.realTarget = edge.target;
      if (!findS) {
        edge.linkedNodeSide = 'source';
        edge.source = switchItem.id;
        edge.laIdTarget = findT.data.laId;
      } else {
        edge.linkedNodeSide = 'target';
        edge.target = switchItem.id;
        edge.laIdSource = findS.data.laId;
      }
      const duplicateEdge = uniqueEdges.find(
        ({ source, target }) =>
          (source === edge.source && target === edge.target) ||
          (source === edge.target && target === edge.source)
      );
      if (!duplicateEdge) uniqueEdges.push(edge);
    }
    switchItem.readOnly = true;
    switchItem.parent = id;
    switchItem.noContextMenu = true;
    switchItem.classes = ['switchBoxItem'];
    switchItem.verticalLinks = structuredClone({
      nodes: l.nodes,
      edges: l.edges,
    });
    switchNodes.push(switchItem);
    switchEdges.push(...uniqueEdges);
  }
  if (!switchNodes.filter((i) => i.id !== id).length) return;
  const { nodes, edges } = yield call(makeGraphModels, {
    nodes: switchNodes,
    edges: switchEdges,
    isTree: false,
  });
  copyNodes.push(...nodes);
  copyEdges.push(...edges);
  yield sendReducerMsg({
    type: MANAGE_LAYER_ACTORS.SUCCESS,
    payload: {
      nodes: copyNodes,
      edges: copyEdges,
    },
    layerId: layer.id,
  });
}

/**
 * Получаем вертикальные связи при открытии вложенного слоя
 */
function* expandedLayerLoaded({ payload }) {
  yield delay(300); // задержка для старта postMessage в открытом iframe
  const activeLayer = yield getActiveLayer();
  const expanded = activeLayer.nodes.filter(
    (i) => i.data.layerSettings && i.data.layerSettings.expand
  );
  if (!expanded.length) return;
  const layers = expanded.map((i) => i.data.actorId);
  if (activeLayer.typeLayer === 'trees') {
    const layerId = activeLayer.id.split('_')[0];
    layers.push(layerId);
  } else if (!activeLayer.isStatic) {
    layers.push(activeLayer.id);
  }

  if (layers.length < MIN_LAYERS_AMOUNT_FOR_VERTICAL_LINKS) return;

  const verticalLinks = yield getVerticalLinks({ payload: { layers } });
  // Отправляем во все вложенные iframe сообщение с вертикальными связями
  for (const node of expanded) {
    const iframe = document.getElementById(`layer_${node.id}`);
    if (!iframe) continue;
    iframe.contentWindow.postMessage(
      {
        appName: PM_APP_NAME,
        type: SET_VERTICAL_LINKS.REQUEST,
        payload: { verticalLinks, ...payload, targetNodeId: node.id },
      },
      '*'
    );
  }
}

function* toggleActorsNumbers({ payload }) {
  const activeLayer = yield getActiveLayer();
  const copyNodes = yield call(getGraphEls, 'nodes', activeLayer.id);
  const copyEdges = yield call(getGraphEls, 'edges', activeLayer.id);
  let maxIndex = 0;
  copyNodes.forEach((i) => {
    if (i.data.isTrace) return;
    maxIndex += 1;
    i.status = 'updated';
    i.data.actorNumber = payload ? maxIndex : null;
  });
  copyEdges.forEach((i) => {
    if (i.data.isTrace) return;
    maxIndex += 1;
    i.status = 'updated';
    i.data.actorNumber = payload ? maxIndex : null;
  });
  yield put({ type: SET_MAX_LAYER_NUMBER, payload: payload ? maxIndex : 0 });
  yield sendReducerMsg({
    type: MANAGE_LAYER_ACTORS.SUCCESS,
    payload: {
      nodes: copyNodes,
      edges: copyEdges,
    },
    layerId: activeLayer.id,
  });
}

function* toggleNodesCoordinates() {
  const activeLayer = yield getActiveLayer();
  const copyNodes = yield call(getGraphEls, 'nodes', activeLayer.id);
  const copyEdges = yield call(getGraphEls, 'edges', activeLayer.id);

  yield sendReducerMsg({
    type: MANAGE_LAYER_ACTORS.SUCCESS,
    payload: {
      nodes: copyNodes.map((node) => ({
        ...node,
        status: 'new',
        replaceAll: true,
      })),
      edges: copyEdges.map((edge) => ({
        ...edge,
        status: 'new',
        replaceAll: true,
      })),
    },
    layerId: activeLayer.id,
  });
}

/**
 * Creates a transfer edge in the graph in real-time
 */
function* wsCreateTransferEdge({ payload }) {
  const { model } = payload;
  const { edgeType, edgeTypeId, source, target, linkedActorId, weight } = model;
  const transferEdgeName = 'transfersAmount';
  const _FILTER_ACCOUNTS_KEY_ = 'actors_layer_filters';
  const splitEdgeType = edgeType.split('_');
  if (splitEdgeType[0] !== transferEdgeName) return;
  const accountsFiltersStr = Utils.fromStorage(_FILTER_ACCOUNTS_KEY_);
  if (!accountsFiltersStr) return;
  const filter = JSON.parse(accountsFiltersStr);
  if (
    filter.nameId !== splitEdgeType[1] ||
    +filter.currencyId !== +splitEdgeType[2]
  ) {
    return;
  }
  const activeLayer = yield getActiveLayer();
  if (!activeLayer) return;
  const sourceActor = activeLayer.nodes.find(
    (i) => !i.data.isTrace && i.data.actorId === source
  );
  const targetActor = activeLayer.nodes.find(
    (i) => !i.data.isTrace && i.data.actorId === target
  );
  if (!sourceActor || !targetActor) return;
  const transferEdge = activeLayer.edges.find(
    (i) =>
      i.data.sourceActorId === source &&
      i.data.targetActorId === target &&
      i.data.edgeTypeId === edgeTypeId &&
      i.classes.includes('transfers')
  );
  const formatParams = {
    type: filter.currencyType,
    precision: filter.currencyPrecision,
    symbol: filter.currencySymbol,
  };
  let body;
  if (transferEdge) {
    const i = structuredClone(transferEdge.data);
    i.transfers.push({ actorId: linkedActorId, weight });
    i.weight += weight;
    i.name = `${AppUtils.formattedAmount(i.weight, formatParams)} ${
      filter.currencyName || ''
    }`;
    body = [
      {
        action: 'update',
        data: { id: i.id, model: i, type: 'edge' },
      },
    ];
  } else {
    body = [
      {
        action: 'create',
        data: {
          id: model.id,
          model: {
            ...model,
            transfers: [{ actorId: linkedActorId, weight }],
            name: `${AppUtils.formattedAmount(weight, formatParams)} ${
              filter.currencyName
            }`,
            laIdSource: sourceActor.data.laId,
            laIdTarget: targetActor.data.laId,
            targetArrow: 'triangle',
            isNonInteractive: true,
            classes: ['transfers'],
          },
          type: 'edge',
        },
      },
    ];
  }
  yield call(manageLayerElements, {
    payload: {
      layerId: activeLayer.id,
      body,
      withReq: false,
      subscribeBalances: false,
    },
  });
}

function* layerElements() {
  yield takeEvery(STARRED_LAYER_ACTOR.REQUEST, starredActor);
  yield takeEvery(EDITABLE_NODE.REQUEST, editableNode);
  yield takeLatest(SAVE_ACTOR_LAYER_SETTINGS.REQUEST, saveActorLayerSettings);
  yield takeEvery(SAVE_LAYER_POSITION.REQUEST, saveLayerPosition);
  yield takeEvery(MANAGE_LAYER_ACTORS.REQUEST, manageLayerElements);
  yield takeEvery(GET_VERTICAL_LINKS.REQUEST, getVerticalLinks);
  yield takeEvery(SET_VERTICAL_LINKS.REQUEST, setVerticalLinks);
  yield takeEvery(EXPANDED_LAYER_LOADED, expandedLayerLoaded);
  yield takeEvery(TOGGLE_ACTORS_LAYER_NUMBERS.REQUEST, toggleActorsNumbers);
  yield takeEvery(TOGGLE_NODES_COORDINATES.REQUEST, toggleNodesCoordinates);
  yield takeEvery(WS_CREATE_EDGE, wsCreateTransferEdge);
}

export default layerElements;
