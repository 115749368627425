import { Utils } from 'mw-style-react';

export const GET_ACTORS_FOLDERS =
  Utils.createRequestTypes('GET_ACTORS_FOLDERS');
export const ADD_ACTOR_FOLDER = Utils.createRequestTypes('ADD_ACTOR_FOLDER');
export const HIDE_ACTOR_FOLDER = Utils.createRequestTypes('HIDE_ACTOR_FOLDER');
export const UPDATE_ACTOR_FOLDER = Utils.createRequestTypes(
  'UPDATE_ACTOR_FOLDER'
);
export const GET_FOLDER_ACTORS = Utils.createRequestTypes('GET_FOLDER_ACTORS');

export const CLEAR_ACTORS_FOLDERS = 'CLEAR_ACTORS_FOLDERS';
export const SET_ACTIVE_ACTORS_FOLDER = 'SET_ACTIVE_ACTORS_FOLDER';

export const BULK_ACTORS_ACTIONS = {
  export: 'export',
  account: 'account',
  remove: 'remove',
  access: 'access',
  tags: 'tags',
  folder: 'folder',
};

export const DEFAULT_FOLDERS_LIMIT = 20;
