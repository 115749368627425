import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconGroup(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.fillStyle = theme.palette.primary;
  ctx.translate(-6, -6);
  ctx.beginPath();
  ctx.moveTo(16.660156, 9.554688);
  ctx.bezierCurveTo(
    16.355469,
    9.402344,
    16.03125,
    9.285156,
    15.699219,
    9.210938
  );
  ctx.bezierCurveTo(
    15.136719,
    9.09375,
    14.550781,
    9.09375,
    13.988281,
    9.210938
  );
  ctx.bezierCurveTo(
    11.695312,
    9.699219,
    10.21875,
    11.980469,
    10.6875,
    14.316406
  );
  ctx.bezierCurveTo(
    11.160156,
    16.652344,
    13.402344,
    18.160156,
    15.699219,
    17.675781
  );
  ctx.bezierCurveTo(
    16.039062,
    17.605469,
    16.355469,
    17.484375,
    16.660156,
    17.335938
  );
  ctx.bezierCurveTo(
    15.046875,
    15.003906,
    15.046875,
    11.886719,
    16.660156,
    9.554688
  );
  ctx.closePath();
  ctx.moveTo(26.277344, 12.574219);
  ctx.bezierCurveTo(
    25.804688,
    10.238281,
    23.558594,
    8.730469,
    21.261719,
    9.214844
  );
  ctx.bezierCurveTo(
    20.921875,
    9.285156,
    20.605469,
    9.410156,
    20.300781,
    9.554688
  );
  ctx.bezierCurveTo(
    19.539062,
    9.925781,
    18.910156,
    10.519531,
    18.480469,
    11.253906
  );
  ctx.bezierCurveTo(
    18.242188,
    11.660156,
    18.054688,
    12.097656,
    17.960938,
    12.574219
  );
  ctx.bezierCurveTo(
    17.84375,
    13.148438,
    17.84375,
    13.742188,
    17.960938,
    14.316406
  );
  ctx.bezierCurveTo(
    18.054688,
    14.792969,
    18.242188,
    15.234375,
    18.480469,
    15.640625
  );
  ctx.bezierCurveTo(
    18.910156,
    16.375,
    19.542969,
    16.964844,
    20.300781,
    17.335938
  );
  ctx.bezierCurveTo(
    20.605469,
    17.484375,
    20.921875,
    17.609375,
    21.261719,
    17.679688
  );
  ctx.bezierCurveTo(
    21.828125,
    17.796875,
    22.410156,
    17.796875,
    22.972656,
    17.679688
  );
  ctx.bezierCurveTo(
    25.269531,
    17.195312,
    26.75,
    14.910156,
    26.277344,
    12.574219
  );
  ctx.closePath();
  ctx.moveTo(11.808594, 23.9375);
  ctx.bezierCurveTo(11.808594, 22.039062, 12.519531, 20.3125, 13.679688, 19);
  ctx.lineTo(11.808594, 19);
  ctx.bezierCurveTo(9.132812, 19, 6.960938, 21.210938, 6.960938, 23.9375);
  ctx.lineTo(6.960938, 25.171875);
  ctx.bezierCurveTo(
    6.960938,
    25.855469,
    7.503906,
    26.40625,
    8.171875,
    26.40625
  );
  ctx.lineTo(12.035156, 26.40625);
  ctx.bezierCurveTo(
    11.890625,
    26.011719,
    11.8125,
    25.59375,
    11.808594,
    25.171875
  );
  ctx.closePath();
  ctx.moveTo(25.144531, 19);
  ctx.lineTo(19.082031, 19);
  ctx.bezierCurveTo(18.921875, 19, 18.765625, 19.023438, 18.609375, 19.046875);
  ctx.bezierCurveTo(
    18.566406,
    19.050781,
    18.519531,
    19.058594,
    18.476562,
    19.0625
  );
  ctx.bezierCurveTo(
    16.054688,
    19.363281,
    14.234375,
    21.453125,
    14.234375,
    23.9375
  );
  ctx.lineTo(14.234375, 25.171875);
  ctx.bezierCurveTo(
    14.234375,
    25.855469,
    14.777344,
    26.40625,
    15.445312,
    26.40625
  );
  ctx.lineTo(28.785156, 26.40625);
  ctx.bezierCurveTo(
    29.105469,
    26.40625,
    29.414062,
    26.277344,
    29.644531,
    26.046875
  );
  ctx.bezierCurveTo(
    29.871094,
    25.816406,
    29.996094,
    25.5,
    29.996094,
    25.171875
  );
  ctx.lineTo(29.996094, 23.9375);
  ctx.bezierCurveTo(29.996094, 21.210938, 27.824219, 19, 25.144531, 19);
  ctx.closePath();
  ctx.moveTo(25.144531, 19);
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
}

export default SVGIconGroup;
