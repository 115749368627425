import { Utils } from 'mw-style-react';

export const GET_ACTORS_FILTERS =
  Utils.createRequestTypes('GET_ACTORS_FILTERS');
export const ADD_ACTORS_FILTER = Utils.createRequestTypes('ADD_ACTORS_FILTER');
export const HIDE_ACTORS_FILTER =
  Utils.createRequestTypes('HIDE_ACTORS_FILTER');
export const UPDATE_FILTER = Utils.createRequestTypes('UPDATE_FILTER');
export const UPDATE_SYSTEM_FILTER = Utils.createRequestTypes(
  'UPDATE_SYSTEM_FILTER'
);
export const ACTORS_FILTER_ACCESS = Utils.createRequestTypes(
  'ACTORS_FILTER_ACCESS'
);

export const CLEAR_ACTORS_FILTERS = 'CLEAR_ACTORS_FILTERS';
export const SET_ACTIVE_ACTORS_FILTER = 'SET_ACTIVE_ACTORS_FILTER';

export const SYSTEM_FILTERS = [
  {
    id: 'all',
    title: 'Recent',
    isSystem: true,
    disabled: true,
    data: {},
  },
  {
    id: 'starred',
    title: 'Starred',
    isSystem: true,
    disabled: true,
    data: {},
  },
];

export const MIN_CHART_FILTER_TOP_VALUE = 1;
export const MAX_CHART_FILTER_TOP_VALUE = 100;
