function nodeModel(node, settings) {
  return {
    id: node.id,
    status: 'new',
    group: 'nodes',
    type: 'node',
    position: node.position,
    static: true,
    replaceAll: settings.replaceAll,
    classes: node.classes,
    data: {
      id: node.id,
      position: node.position,
      group: 'nodes',
      type: 'node',
      static: true,
      ...node,
    },
  };
}

function edgeModel({ source, target, settings }) {
  return {
    id: `edge_${source}_${target}`,
    status: 'new',
    type: 'edge',
    group: 'edges',
    edgeType: 'hierarchy',
    edgeId: `edge_${source}_${target}`,
    source,
    target,
    title: '',
    replaceAll: settings.replaceAll,
    data: {
      id: `edge_${source}_${target}`,
      type: 'edge',
      group: 'edges',
      edgeType: 'hierarchy',
      edgeId: `edge_${source}_${target}`,
      source,
      target,
      title: '',
      isNonInteractive: true,
      isTree: settings.isTree,
      targetArrow: settings.isTree ? 'triangle' : undefined,
    },
  };
}

function extendActorGraph({ nodeId, els, children, parents, settings }) {
  const mainNode = els.find((i) => i.id === nodeId);
  const childrenNodes = children.map((i) => nodeModel(i, settings));
  const parentsNodes = parents.map((i) => nodeModel(i, settings));
  const childrenEdges = children.map(({ id }) =>
    edgeModel({ source: mainNode.id, target: id, settings })
  );
  const parentEdges = parents.map(({ id }) =>
    edgeModel({ source: id, target: mainNode.id, settings })
  );
  return [
    ...els,
    ...childrenNodes,
    ...parentsNodes,
    ...childrenEdges,
    ...parentEdges,
  ];
}

export default extendActorGraph;
