import React, {
  useEffect,
  useState,
  useRef,
  createRef,
  isValidElement,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import keyBy from 'lodash/keyBy';
import flatten from 'lodash/flatten';
import cn from 'classnames';
import {
  Modal,
  ModalButtons,
  ModalContent,
  Button,
  ProgressBar,
  Label,
  Checkbox,
  ToggleSwitch,
  Utils,
  TextField,
} from 'mw-style-react';

import { SET_MODAL, DEL_MODAL, UPDATE_USER_SETTINGS } from 'constants';
import { useNotifications, useIntl } from 'hooks';
import { ActorGeneralFields, ActorModalIdChip } from 'components';
import {
  GET_FORM,
  CLEAR_FORM,
  KEY_FORM_PREFIX,
} from '@control-front-end/common/constants/forms';
import {
  CHANGE_SYSTEM_ACTOR,
  CREATE_ACTOR,
  REMOVE_ACTOR_FORMS,
  UPDATE_ACTOR,
  GET_ACTOR_VIEW_DATA,
  GET_ACTOR,
} from '@control-front-end/common/constants/graphActors';
import AppUtils from '@control-front-end/utils/utils';
import FormsLayout from './components/FormsLayout';
import { FORM_UI } from './constatns';
import { formUIPropTypes } from './propTypes';
import mes from './intl';
// eslint-disable-next-line no-unused-vars
import sModal from '../../Modal.scss'; // NOSONAR
// eslint-disable-next-line no-unused-vars
import sLocal from './CreateActor.scss'; // NOSONAR

const CLEAR_ACTOR_DATA = {
  title: '',
  description: '',
  ref: null,
  color: null,
  picture: null,
  pictureUrl: '',
};

// Provides new form list but saves fields values from previous forms list
const mergeFormListWithFieldValues = ({ prevForms, newForms }) => {
  const formsMap = keyBy(prevForms, 'id');
  return newForms.map(({ form, ...rest }) => {
    if (!formsMap[form.id]) return { form, ...rest };

    const prevFormFieldsMap = keyBy(
      flatten(formsMap[form.id].form.sections.map(({ content }) => content)),
      'id'
    );

    return {
      form: {
        ...form,
        sections: form.sections.map((section) => ({
          ...section,
          content: section.content.map((field) => ({
            ...field,
            value: prevFormFieldsMap[field.id]?.value || field.value,
          })),
        })),
      },
      ...rest,
    };
  });
};

const prepareViewDataToPost = (viewData) =>
  viewData
    ? {
        formsFields: viewData.formsFields.reduce((result, field) => {
          if (field.formId && field.itemId) {
            result.push(Utils.pick(field, ['formId', 'itemId', 'icon']));
          }
          return result;
        }, []),
        actorFields: viewData.actorFields.map((item) =>
          Utils.pick(item, ['itemId', 'icon'])
        ),
        accounts: viewData.accounts.map((item) =>
          Utils.pick(item, ['nameId', 'currencyId', 'displayCurrency'])
        ),
        actors: Object.keys(viewData.actors).reduce((result, keyName) => {
          if (!viewData.actors[keyName]) return result;
          result[keyName] = {
            actorId:
              viewData.actors[keyName].actorId || viewData.actors[keyName].id,
          };
          return result;
        }, {}),
        design: viewData.design,
      }
    : null;

function CreateActor(props) {
  const {
    data,
    visibility,
    onContentChange,
    onClose,
    callback,
    modalName,
    settings,
    mainData: mainDataProp,
  } = props;

  const { form, onSubmitForm, onSuccess, modifyViewData } = data;
  const t = useIntl();
  const formRefs = useRef([]);
  const dispatch = useDispatch();
  const { showNotification } = useNotifications();
  const { actorOptionalFields } = useSelector((state) => state.settings);
  const { filter } = useSelector((state) => state.actorsList);
  const systemForms = useSelector((state) => state.systemForms) || {};
  const systemFormsSnippets = systemForms.snippets || {};
  const formView = useSelector((state) => state.formView);
  const formColor = formView.form ? formView.form.color : null;
  const defaultTpl = useSelector((state) => state.defaultActorTpl);
  const [formUdid, setFormUdid] = useState(AppUtils.udid());
  const [isLoading, setLoading] = useState(false);
  const [mainData, setMainData] = useState({
    title: props.data.title || '',
    description: props.data.description || '',
    ref: props.data.ref || null,
    color: props.data.color || null,
    picture: props.data.picture || null,
    pictureUrl: props.data.pictureUrl || '',
    systemObjId:
      props.data.systemObjType === 'user' ? props.data.systemObjId : null,
    viewData: props.data.viewData || null,
    status: props.data.status || null,
  });

  useEffect(() => {
    if (!mainDataProp) return;
    setMainData((prevState) => ({
      ...prevState,
      ...mainDataProp,
    }));
  }, [mainDataProp]);

  const [forms, setForms] = useState([]);
  const [formsErrors, setFormsErrors] = useState([]);
  const [createAnotherFlag, toggleCreateAnotherFlag] = useState(false);
  let rootForm = (forms || []).find((f) => f.id === data?.formId);
  if (!rootForm) rootForm = (forms || []).find((f) => !f.parentId);
  const noForms = !forms.length;
  const isSystemForm =
    forms.length === 1 &&
    (rootForm?.type === 'system' || rootForm?.form?.type === 'system');

  const handleToggleOptional = ({ value }) => {
    dispatch({
      type: UPDATE_USER_SETTINGS.REQUEST,
      payload: { actorOptionalFields: value },
    });
  };

  const updateActorForms = (list) => {
    setForms(list);
    formRefs.current = list.map(
      (item, i) => formRefs.current[i] ?? createRef()
    );
  };

  const getFormWithRelations = () => {
    if (!form || !form.id) return;
    setLoading(true);
    dispatch({
      type: GET_FORM.REQUEST,
      payload: { formId: form.id, withRelations: true },
      afterReq: (res) => {
        const orderedFromRoot = res.forms.slice().reverse();
        setLoading(false);
        updateActorForms(orderedFromRoot);
        // устанавливаем цвет нового актора = цвет выбранной формы
        if (!props.data.id) {
          setMainData((prevState) => ({ ...prevState, color: res.color }));
        }
      },
    });
  };

  useEffect(() => {
    const makeForms = (forms) =>
      forms.map(({ id, title, parentId, isDefault, ...rest }) => ({
        id,
        title,
        parentId,
        isDefault,
        form: { id, title, parentId, isDefault, ...rest },
      }));

    if (props.data.forms) {
      const sortedList = Utils.sort(makeForms(props.data.forms), 'parentId');
      updateActorForms(sortedList);
    } else if (props.data.id) {
      dispatch({
        type: GET_ACTOR.REQUEST,
        payload: { id: props.data.id },
        callback: (actor) => {
          setForms(makeForms(actor.forms));
        },
      });
    } else {
      getFormWithRelations();
    }

    return () => {
      if (!filter?.formId || !form?.id) dispatch({ type: CLEAR_FORM });
    };
  }, [props.data]);

  // If actor already exists - then get viewData of this actor
  useEffect(() => {
    if (!props.data.id) return;
    dispatch({
      type: GET_ACTOR_VIEW_DATA.REQUEST,
      payload: { id: props.data.id },
      callback: (data) =>
        setMainData((prevValue) => ({
          ...prevValue,
          viewData: data,
        })),
    });
  }, [props.data.id]);

  const notifySaveSuccess = ({ title }) => {
    const { id } = props.data;
    const message = t(mes[id ? 'actorUpdated' : 'actorCreated'], {
      title,
    });
    showNotification('success', message);
  };

  const handleFormsErrors = () => {
    const formsErrs = [];
    forms.forEach((f, index) => {
      if (formRefs.current[index]?.current)
        formsErrs.push(...formRefs.current[index].current.getFormErrors());
    });
    setFormsErrors(formsErrs);
  };

  const changeSystemActor = ({ id, systemObjId }) => {
    const userId = mainData.systemObjId;
    if (!userId || userId === systemObjId) return;
    dispatch({
      type: SET_MODAL,
      payload: {
        name: 'InfoModal',
        data: {
          title: 'changeSystemActor',
          text: 'changingSystemActor',
          buttons: [
            {
              label: t(mes.saveChangesAnyway),
              onClick: () => {
                dispatch({
                  type: CHANGE_SYSTEM_ACTOR.REQUEST,
                  payload: {
                    objType: 'user',
                    objId: userId,
                    targetActorId: id,
                  },
                });
                dispatch({ type: DEL_MODAL, payload: 'InfoModal' });
                dispatch({ type: DEL_MODAL, payload: modalName });
              },
            },
          ],
        },
      },
    });
  };

  const onSaveActor = (actorFormId, actorData, formData) => {
    const { id, systemObjId, reduxEvent } = props.data;
    const userId = mainData.systemObjId;
    setLoading(true);
    dispatch({
      type: id ? UPDATE_ACTOR.REQUEST : CREATE_ACTOR.REQUEST,
      payload: { ...actorData, id, formId: actorFormId, formData, reduxEvent },
      callback: (actor) => {
        notifySaveSuccess(actor);
        if (onSuccess) onSuccess({ actor });

        if (createAnotherFlag) {
          setMainData(CLEAR_ACTOR_DATA);
          setFormUdid(AppUtils.udid());
          return;
        }
        if (callback) callback(actor);
        if (userId && userId !== systemObjId) {
          changeSystemActor(actor);
        } else {
          dispatch({ type: DEL_MODAL, payload: modalName });
        }
      },
      formActions: {
        setSubmitting: (value) => setLoading(value),
      },
    });
  };

  const removeActorForms = (list, callback) => {
    dispatch({
      type: REMOVE_ACTOR_FORMS.REQUEST,
      payload: { actorId: props.data.id, forms: list },
      callback,
    });
  };

  const handleSubmit = () => {
    const { id, areaPicture, pictureObject, position, manageLayer } =
      props.data;
    if (id && !rootForm) {
      showNotification('error', t(mes.cantSaveWithoutForm));
      return;
    }
    const formData = {};
    const formOrder = [];
    setFormsErrors([]);
    let error = false;
    const hasNoAccessForm = forms.some((f) => f.accessDenied);
    if (hasNoAccessForm) {
      showNotification('error', t(mes.formAccessError));
      return;
    }
    forms.forEach((f, index) => {
      formOrder.push(f.id);
      const filledFormData =
        !f.isDefault && formRefs.current[index]?.current
          ? formRefs.current[index].current.getFormData()
          : {};
      if (!filledFormData) error = true;
      Object.keys(filledFormData).forEach((key) => {
        if (rootForm && f.id === rootForm.id) {
          formData[key] = filledFormData[key];
        } else {
          formData[`${KEY_FORM_PREFIX}${f.id}:${key}`] = filledFormData[key];
        }
      });
    });
    if (error) {
      setTimeout(() => handleFormsErrors(), 0);
      return;
    }

    const viewDataToPost = prepareViewDataToPost(
      modifyViewData
        ? modifyViewData({ viewData: mainData.viewData, formData })
        : mainData.viewData
    );

    const actorData = {
      ...mainData,
      viewData: viewDataToPost,
      areaPicture,
      pictureObject,
      position,
      manageLayer,
      formOrder,
    };
    // создание нового актора
    if (!id) {
      const actorFormId = rootForm ? rootForm.id : defaultTpl.id;
      onSaveActor(actorFormId, actorData, formData);
      return;
    }
    // обновление актора и данных его форм
    const actorFormId = rootForm ? rootForm.id : form.id;
    const removedForms = (props.data.forms || [])
      .filter((actorForm) => !forms.find((form) => form.id === actorForm.id))
      .map((i) => i.id);
    const updateActor = () => onSaveActor(actorFormId, actorData, formData);
    if (removedForms.length) {
      removeActorForms(removedForms, updateActor);
    } else {
      updateActor();
    }
  };

  const renderLoader = () => (
    <div styleName="sLocal.content__loader">
      <ProgressBar type="circle" size="large" />
    </div>
  );

  const getDefaultActorFields = () => {
    const isSnippet = rootForm && rootForm.id === systemFormsSnippets.id;
    return (
      <div
        styleName={cn('sLocal.content__default', {
          noForms,
          optionalFields: settings.UI.optionalFields && !!actorOptionalFields,
        })}
      >
        <ActorGeneralFields
          title={mainData.title}
          refId={mainData.ref}
          systemObjId={mainData.systemObjId}
          description={mainData.description}
          pictureUrl={mainData.pictureUrl}
          colors={[
            { type: 'actor', color: mainData.color },
            { type: 'form', color: formColor },
          ]}
          showOptional={settings.UI.optionalFields && !!actorOptionalFields}
          isSystemForm={isSystemForm}
          onChange={(newData) => {
            onContentChange();
            setMainData((prevState) => ({ ...prevState, ...newData }));
          }}
        />
        {settings.UI.description || isSnippet ? (
          <TextField
            styleName="sLocal.content__default__descr"
            bordered={true}
            multiline={true}
            rows={2}
            label={t(mes.description)}
            value={mainData.description}
            onChange={({ value }) =>
              setMainData((prevState) => ({ ...prevState, description: value }))
            }
          />
        ) : null}
      </div>
    );
  };

  return (
    <Modal
      id="createActorModal"
      styleName={cn('sModal.modal sLocal.modal', { noForms })}
      visibility={visibility}
      onClose={onClose}
      label={`Actor: ${props.data.id ? mainData.title : t(mes.newActor)}`}
      size="xlarge"
    >
      <ActorModalIdChip actorId={props.data.id} formId={props.formId} />
      <div id="portalActions" styleName="sLocal.content__actions" />
      <ModalContent
        styleName={cn('sModal.modal__content sLocal.content', { noForms })}
      >
        <FormsLayout
          key={formUdid}
          actor={props.data}
          mainData={mainData}
          type="modal"
          forms={forms}
          formRefs={formRefs}
          extraSection={getDefaultActorFields()}
          onFormItemLoading={setLoading}
          onFormDataChange={({ id, form }) => {
            onContentChange();
            setTimeout(() => handleFormsErrors(), 0);
            if (!id || !form) return;
            updateActorForms(
              forms.map((item) => (item.id === id ? { ...item, form } : item))
            );
          }}
          onChange={(newData = {}) => {
            onContentChange();
            setTimeout(() => handleFormsErrors(), 0);
            if (!Object.keys(newData).length) return;
            setMainData((prevState) => ({
              ...prevState,
              ...newData,
            }));
          }}
          onSubmit={onSubmitForm}
          onChangeForms={(newForms) =>
            updateActorForms(
              mergeFormListWithFieldValues({ prevForms: forms, newForms })
            )
          }
          formsErrors={formsErrors}
          settings={settings}
        />
        {isLoading ? renderLoader() : null}
      </ModalContent>
      <ModalButtons styleName="sModal.modal__buttons sLocal.modal__actions">
        {isValidElement(settings.UI.bottomToggle)
          ? settings.UI.bottomToggle
          : null}
        {settings.UI.bottomToggle === true ? (
          <div>
            <ToggleSwitch
              value={actorOptionalFields}
              onChange={handleToggleOptional}
            />
            <Label value={t(mes.optionalFields)} />
          </div>
        ) : null}
        <div styleName="sLocal.modal__actions__submit__panel">
          <ProgressBar
            type="circle"
            size="small"
            visibility={isLoading ? 'visible' : 'hidden'}
          />
          <Checkbox
            styleName="sLocal.modal__actions__checkbox"
            value={createAnotherFlag}
            onChange={() => toggleCreateAnotherFlag(!createAnotherFlag)}
            visibility={
              settings.UI.createAnotherActorCheckbox && !props.data.id
                ? 'visible'
                : 'hidden'
            }
          >
            <Label value={t(mes.createAnotherActor)} />
          </Checkbox>
          <Button
            size="smallplus"
            label={t(mes.save)}
            onClick={handleSubmit}
            disabled={isLoading}
            visibility={isLoading ? 'disabled' : 'visible'}
          />
        </div>
      </ModalButtons>
    </Modal>
  );
}

CreateActor.FORM_UI = FORM_UI;

CreateActor.defaultProps = {
  modalName: 'CreateActor',
  settings: {
    UI: {
      ...Object.keys(FORM_UI).reduce(
        (acc, key) => ({ ...acc, [key]: true }),
        {}
      ),
      [FORM_UI.description]: false,
    },
    actorCardViewEditor: {},
  },
};

CreateActor.propTypes = {
  visibility: PropTypes.bool.isRequired,
  data: PropTypes.object,
  onClose: PropTypes.func,
  callback: PropTypes.func,
  onContentChange: PropTypes.func,
  modalName: PropTypes.string,
  /*
   * Customization params used by modals created on the top of the CreateActor for specific forms
   * TOMAKE: CreateActor modal should be refactored and splited into
   * different components to avoid lots of if-else statements
   */
  settings: PropTypes.shape({
    UI: formUIPropTypes,
    actorCardViewEditor: PropTypes.shape({
      forceDisplayWidgetSelector: PropTypes.bool,
    }),
  }),
};

export default CreateActor;
