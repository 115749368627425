import { Utils } from 'mw-style-react';
import { SYSTEM_LAYERS_NAMES } from '@control-front-end/common/constants/graphLayers';

// COMMON
export const CONFIG = Utils.createRequestTypes('CONFIG');
export const GET_CONFIG = Utils.createRequestTypes('GET_CONFIG');
export const APP_INIT = Utils.createRequestTypes('APP_INIT');
export const APP_AUTH_START = Utils.createRequestTypes('APP_AUTH_START');
export const AUTH = Utils.createRequestTypes('AUTH');
export const SET_TAB_ID = Utils.createRequestTypes('SET_TAB_ID');

export const LOGOUT = Utils.createRequestTypes('LOGOUT');
export const SHOW_NOTIFY = Utils.createRequestTypes('SHOW_NOTIFY');
export const HIDE_NOTIFY = Utils.createRequestTypes('HIDE_NOTIFY');
export const SET_THEME = Utils.createRequestTypes('SET_THEME');
export const SUPPORT_SEND = Utils.createRequestTypes('SUPPORT_SEND');

export const SET_DATA_CACHE = 'SET_DATA_CACHE';
export const CACHE_TTL = 3 * 60 * 1000;

// WORKSPACES
export const ACCOUNTS = Utils.createRequestTypes('ACCOUNTS');
export const SEARCH_ACCOUNTS = Utils.createRequestTypes('SEARCH_ACCOUNTS');
export const ACCOUNT_CREATE = Utils.createRequestTypes('ACCOUNT_CREATE');
export const ACCOUNT_UPDATE = Utils.createRequestTypes('ACCOUNT_UPDATE');
export const ACCOUNT_DELETE = Utils.createRequestTypes('ACCOUNT_DELETE');
export const GET_ACTIVE_ACC = Utils.createRequestTypes('GET_ACTIVE_ACC');

// INVITES
export const SEND_INVITE = Utils.createRequestTypes('SEND_INVITE');
export const GET_INVITES = Utils.createRequestTypes('GET_INVITES');
export const UPDATE_INVITE = Utils.createRequestTypes('UPDATE_INVITE');
export const CANCEL_INVITE = Utils.createRequestTypes('CANCEL_INVITE');
export const CLEAR_INVITES = 'CLEAR_INVITES';

// USERS
export const GET_USERS = Utils.createRequestTypes('GET_USERS');
export const GET_USERS_BULK = Utils.createRequestTypes('GET_USERS_BULK');
export const GET_USER = Utils.createRequestTypes('GET_USER');
export const SEARCH_USERS = Utils.createRequestTypes('SEARCH_USERS');
export const SET_USERS_REQ_STATUS = 'SET_USERS_REQ_STATUS';
export const CLEAR_USERS = 'CLEAR_USERS';
export const GET_USER_SETTINGS = Utils.createRequestTypes('GET_USER_SETTINGS');
export const GET_USERS_ROLES = Utils.createRequestTypes('GET_USERS_ROLES');
export const UPDATE_USER_SETTINGS = Utils.createRequestTypes(
  'UPDATE_USER_SETTINGS'
);

// GROUPS
export const GET_GROUPS = Utils.createRequestTypes('GET_GROUPS');
export const GET_GROUP = Utils.createRequestTypes('GET_GROUP');
export const GET_GROUP_MEMBERS = Utils.createRequestTypes('GET_GROUP_MEMBERS');
export const SET_GROUPS_REQ_STATUS = 'SET_GROUPS_REQ_STATUS';
export const CLEAR_GROUPS = 'CLEAR_GROUPS';

// FILES
export const UPLOAD_FILE = Utils.createRequestTypes('UPLOAD_FILE');
export const GET_EMOJI_IMAGE = Utils.createRequestTypes('GET_EMOJI_IMAGE');
export const GET_FILES = Utils.createRequestTypes('GET_FILES');
export const STARRED_FILE = Utils.createRequestTypes('STARRED_FILE');
export const SEARCH_FILES = Utils.createRequestTypes('SEARCH_FILES');
export const CLEAR_FILES = 'CLEAR_FILES';
export const SET_FILES_REQ_STATUS = 'SET_FILES_REQ_STATUS';
export const GET_ACTOR_ATTACHMENTS = Utils.createRequestTypes(
  'GET_ACTOR_ATTACHMENTS'
);
export const GET_ACTOR_REACTIONS_ATTACHMENTS = Utils.createRequestTypes(
  'GET_ACTOR_REACTIONS_ATTACHMENTS'
);

// SEARCH
export const SEARCH = Utils.createRequestTypes('SEARCH');
export const RECENT = Utils.createRequestTypes('RECENT');
export const RECENT_ACTORS = Utils.createRequestTypes('RECENT_ACTORS');
export const RECENT_ACTORS_GROUPED_BY_TPL = Utils.createRequestTypes(
  'RECENT_ACTORS_GROUPED_BY_TPL'
);
export const CLEAR_SEARCH = 'CLEAR_SEARCH';
export const SET_SEARCH_REQ_STATUS = 'SET_SEARCH_REQ_STATUS';
export const SEARCH_DEBOUNCE_INTERVAL = 500;

// STATIC LAYERS
export const MY_VERSE = Utils.createRequestTypes('MY_VERSE');
export const OPEN_STATIC_LAYER = 'OPEN_STATIC_LAYER';
export const STATIC_LAYER = {
  myVerse: 'myVerse',
  valency: 'valency',
  accessGraph: 'accessGraph',
  formGraph: 'formGraph',
  actorFormsGraph: 'actorFormsGraph',
  ...SYSTEM_LAYERS_NAMES,
};

// WEBHOOKS
export const CREATE_WEBHOOK = Utils.createRequestTypes('CREATE_WEBHOOK');
export const UPDATE_WEBHOOK = Utils.createRequestTypes('UPDATE_WEBHOOK');
export const GET_WEBHOOKS = Utils.createRequestTypes('GET_WEBHOOKS');
export const DELETE_WEBHOOK = Utils.createRequestTypes('DELETE_WEBHOOK');
export const SET_WEBHOOKS_REQ_STATUS = 'SET_WEBHOOKS_REQ_STATUS';
export const CLEAR_WEBHOOKS = 'CLEAR_WEBHOOKS';

// IMPORT & EXPORT ASYNC TASKS
export const GET_ASYNC_TASKS = Utils.createRequestTypes('GET_ASYNC_TASKS');
export const CREATE_ASYNC_TASK = Utils.createRequestTypes('CREATE_ASYNC_TASK');
export const UPDATE_ASYNC_TASK = Utils.createRequestTypes('UPDATE_ASYNC_TASK');
export const DELETE_ASYNC_TASK = Utils.createRequestTypes('DELETE_ASYNC_TASK');
export const SET_ASYNC_TASKS_REQ_STATUS = 'SET_ASYNC_TASKS_REQ_STATUS';
export const CLEAR_ASYNC_TASKS = 'CLEAR_ASYNC_TASKS';
export const WS_EXPORT = 'WS_EXPORT';
export const WS_IMPORT = 'WS_IMPORT';
export const ASYNC_TASK_STATUS_LIST = [
  'created',
  'started',
  'completed',
  'failed',
  'canceled',
];
export const CANCELLABLE_TASK_STATUS_LIST = ['created', 'started'];
export const ASYNC_TASK_UPDATE_DELAY = 10;

// ACCESS RULES
export const GET_ACCESS_RULES = Utils.createRequestTypes('GET_ACCESS_RULES');
export const MANAGE_ACCESS_RULES = Utils.createRequestTypes(
  'MANAGE_ACCESS_RULES'
);
export const BULK_MANAGE_ACCESS_RULES = Utils.createRequestTypes(
  'BULK_MANAGE_ACCESS_RULES'
);
export const BULK_MANAGE_PAIRS_ACCESS_RULES = Utils.createRequestTypes(
  'BULK_MANAGE_PAIRS_ACCESS_RULES'
);

// HISTORY
export const GET_OBJECT_HISTORY =
  Utils.createRequestTypes('GET_OBJECT_HISTORY');
export const FILTER_HISTORY = Utils.createRequestTypes('FILTER_HISTORY');
export const ACTOR_MODEL_HISTORY_FIELDS = [
  'title',
  'description',
  'access',
  'attachments',
  'color',
  'picture',
  'pictureObject',
  'status',
  'appId',
  'appSettings',
  'cardActorId',
  'forms',
  'account',
  'links',
];

// COMMON SINGLE
export const APP_AUTH_KEY = 'scSID';
export const SSO_AUTH_KEY = 'scSSO';
export const APP_NAME = 'APP_NAME';
export const WINDOW_FOCUSED = 'WINDOW_FOCUSED';
export const CHECK_VERSION = 'CHECK_VERSION';
export const SHOW_VERSION_MODAL = 'SHOW_VERSION_MODAL';
export const SHOW_SESSION_MODAL = 'SHOW_SESSION_MODAL';
export const SET_MODAL = 'SET_MODAL';
export const SET_LOCATION = 'SET_LOCATION';
export const DEL_MODAL = 'DEL_MODAL';
export const REPLACE_MODAL = 'REPLACE_MODAL';
export const SET_ACTIVE_ACC = 'SET_ACTIVE_ACC';
export const SET_NEW_ACC = 'SET_NEW_ACC';
export const CREATE_DELAYED_ACTION = 'CREATE_DELAYED_ACTION';
export const CLEAR_DELAYES_ACTION = 'CLEAR_DELAYES_ACTION';
export const CLEAR_NOTIFY = 'CLEAR_NOTIFY';
export const SET_STARRED_SCRIPTS = 'SET_STARRED_SCRIPTS';

// WIDGET
export const ADD_WIDGET = 'ADD_WIDGET';
export const REMOVE_WIDGET = 'REMOVE_WIDGET';
export const CLEAR_WIDGETS = 'CLEAR_WIDGETS';
export const CLEAR_WIDGETS_BY_NAME = 'CLEAR_WIDGETS_BY_NAME';
export const CLEAR_WIDGETS_BY_ACTORID = 'CLEAR_WIDGETS_BY_ACTORID';
export const UPDATE_WIDGET = 'UPDATE_WIDGET';

// WS EVENTS
export const WS_OPEN = 'WS_OPEN';
export const WS_RECONNECTION = 'WS_RECONNECTION';
export const WS_RECONNECTION_SUCCESS = 'WS_RECONNECTION_SUCCESS';
export const WS_CLOSE = 'WS_CLOSE';
export const WS_ERROR = 'WS_ERROR';
export const WS_SEND = 'WS_SEND';
export const WS_NEW_NOTIFY = 'WS_NEW_NOTIFY';
export const WS_CLEAR_NOTIFY = 'WS_CLEAR_NOTIFY';
export const WS_CLEAR_ACTOR_NOTIFY = 'WS_CLEAR_ACTOR_NOTIFY';
export const WS_CREATE_ACCESS = 'WS_CREATE_ACCESS';
export const WS_UPDATE_ACCESS = 'WS_UPDATE_ACCESS';
export const WS_DELETE_ACCESS = 'WS_DELETE_ACCESS';
export const WS_UPDATE_SESSION = 'WS_UPDATE_SESSION';
export const WS_GRAPH_SUBSCRIBERS = 'WS_GRAPH_SUBSCRIBERS';

// postMessage actions
export const PM_APP_NAME = 'scPostMessage';
export const PM_START = 'PM_START';
export const PM_SEND = 'PM_SEND';
export const PM_INIT_OPTIONS = 'PM_INIT_OPTIONS';

// Widgets namespaces
export const WIDGET_NAMESPACE = {
  DASHBOARD: 'webDashboard',
};

// Sound
export const MAKE_SOUND = 'MAKE_SOUND';
export const SOUND_TYPE = {
  notification: 'notification',
  moveActor: 'moveActor',
  addActor: 'addActor',
  addLink: 'addLink',
  delete: 'delete',
};
export const SOUND_CATEGORIES = {
  graphActions: [
    SOUND_TYPE.moveActor,
    SOUND_TYPE.addActor,
    SOUND_TYPE.addLink,
    SOUND_TYPE.delete,
  ],
};

// Initializes UI texts/translations
export const INIT_TRANSLATIONS = Utils.createRequestTypes('INIT_TRANSLATIONS');

// Electron
export const ELECTRON_NAVIGATE = 'ELECTRON_NAVIGATE';

export const BULK_ACTIONS_LIMIT = 50;

export const NOTIFY_LEVEL = {
  SUCCESS: 'success',
  INFO: 'normal',
  WARNING: 'warning',
  ERROR: 'error',
};

export const DATE_FORMAT = 'm DD, YYYY; HH:mm';
export const DATE_FORMAT_1 = 'DD m HH:mm';
export const DATE_FORMAT_2 = 'DD m YYYY';
export const DATE_FORMAT_3 = 'YYYY/MM/DD HH:mm:ss';
export const DATE_FORMAT_4 = 'DD M YYYY, HH:mm';
export const DATE_FORMAT_5 = 'HH:mm';
export const DATE_FORMAT_6 = 'DD.MM.YYYY';
export const DATE_FORMAT_7 = 'HH:mm:ss';

export const MILLISECONDS_PER_SECOND = 1000;

export const ORDER = {
  ASC: 'ASC',
  DESC: 'DESC',
};

export const STATUS = {
  new: 'new',
  deleted: 'deleted',
};

export const AccessLevel = Object.freeze({
  SIGN: 'sign',
  EXECUTE: 'execute',
  MODIFY: 'modify',
  VIEW: 'view',
  REMOVE: 'remove',
  RESULT: 'result',
});

export const ACCOUNT_ROLE_ACTION = Object.freeze({
  VIEW: 'view',
  SIGN: 'sign',
  DONE: 'done',
});

export const WORKSPACE_ROLE = Object.freeze({
  GUEST: 'guest',
  ADMIN: 'admin',
  OWNER: 'owner',
  USER: 'user',
});

export const DEFAULT_OWNER_PRIVS = {
  view: true,
  modify: true,
  remove: true,
  sign: false,
  execute: false,
};

export const NO_PRIVS = {
  view: false,
  modify: false,
  remove: false,
  sign: false,
  execute: false,
};

// The priority of types (key order) is the same as already
// implemented in the Single Account, the order is IMPORTANT
export const LOGIN_TYPE_NAMES = {
  google: 'google',
  ldap: 'ldap',
  corezoid: 'corezoid',
  facebook: 'facebook',
  oauth_pb: 'oauth_pb',
  phone: 'phone',
  github: 'github',
  apple: 'apple',
  keycloak: 'keycloak',
  api: 'api',
};
// User can be with numeric login type ID or with string login
// type name, so we have mapping for them
export const LOGIN_TYPES = {
  1: LOGIN_TYPE_NAMES.api,
  2: LOGIN_TYPE_NAMES.google,
  3: LOGIN_TYPE_NAMES.facebook,
  4: LOGIN_TYPE_NAMES.ldap,
  5: LOGIN_TYPE_NAMES.oauth_pb,
  6: LOGIN_TYPE_NAMES.phone,
  7: LOGIN_TYPE_NAMES.corezoid,
  8: LOGIN_TYPE_NAMES.github,
  9: LOGIN_TYPE_NAMES.apple,
  10: LOGIN_TYPE_NAMES.keycloak,
};

export const PARTICIPANT_TYPE = { user: 'user', group: 'group' };

export const USERS_TYPES = {
  user: 'user',
  api: 'api',
  anonymous: 'anonymous',
  system: 'system',
  group: 'group',
};
export const USER_TYPE_WITHOUT_PREVIEW = Utils.pick(USERS_TYPES, [
  USERS_TYPES.api,
  USERS_TYPES.anonymous,
  USERS_TYPES.system,
]);

export const ACTION_ICONS = {
  execute: 'check_box',
  sign: 'sign',
  reject: 'declined',
};

// Possible entities operated on the platform
export const OBJECT_TYPE = {
  form: 'form',
  actor: 'actor',
  account: 'account',
  currency: 'currency',
  user: 'user',
  transfer: 'transfer',
  transaction: 'transaction',
  templateActors: 'templateActors',
  formTemplate: 'formTemplate',
  treeLayer: 'treeLayer',
};

export const DELETED_USER_NAME = 'User deleted';

export const RequestStatus = Object.freeze({
  INIT: 'init',
  PROGRESS: 'inProgress',
  SUCCESS: 'success',
  ERROR: 'error',
  UNAUTH: 'unauth',
});

export const SCRIPT_SHARE_TYPES = [
  'userList',
  'allWorkspaceUsers',
  'allRegisteredUsers',
  'anyone',
];

export const ACCEPT_FILES =
  'image/*,text/*,application/*,video/*,audio/*,font/*,.xls,.sql,.doc,.docx,.odt,.rtf,.graph,.har';

export const VIEWPORT = {
  MOB: 'MOB',
  TABLET: 'TABLET',
  DESKTOP: 'DESKTOP',
};
export const VIEWPORT_BREAKPOINT = {
  [VIEWPORT.MOB]: 768,
  [VIEWPORT.TABLET]: 1200,
  [VIEWPORT.DESKTOP]: Infinity,
};

export const GRAPH_CELL_SIZE = 50;
export const GRAPH_MIN_ZOOM = 0.4;
export const GRAPH_MAX_ZOOM = 2;
export const DESKTOP_WINDOW_SIZE = 1024;
export const DEFAULT_FORM_COLOR = '#EAECEF';
export const PAGINATION_PER_PAGE_OPTIONS = [15, 25, 50];

export const HOT_KEYS = {
  Backspace: 8,
  Delete: 46,
  KeyC: 67,
  KeyL: 76,
  KeyS: 83,
  KeyV: 86,
  KeyX: 88,
};

// Colors
export const ACTIVE_ICON_COLOR = '#1973E8';

export const WIDGET_TYPE = {
  webWidget: 'webWidget',
  webComments: 'webComments',
  webSIP: 'webSIP',
};

export const SIP_ACTOR_ID = 'sip';

export const ERROR_CODES = {
  accessDenied: 403,
};

export const TIMEFRAME_NAME = {
  all: 'all',
  lastHour: 'lastHour',
  last10Minutes: 'last10Minutes',
  lastMinute: 'lastMinute',
  today: 'today',
  yesterday: 'yesterday',
  lastWeek: 'lastWeek',
  lastMonth: 'lastMonth',
  previousMonth: 'previousMonth',
  allTime: 'allTime',
  realTime: 'realTime',
  lineRealTime: 'lineRealTime',
  now: 'now',
  custom: 'custom',
};

export const EVENTS_VIEW_MODES = {
  list: 'list',
  calendar: 'calendar',
};

export const CALENDAR_VIEWS = {
  dayGridMonth: 'dayGridMonth',
  timeGridWeek: 'timeGridWeek',
  timeGridDay: 'timeGridDay',
};

/**
 * Possible settings wich allows user to customize Simulator appearance or behaviour
 */
export const APP_SETTINGS = {
  notificationsSettings: 'notificationsSettings',
  plainMode: 'plainMode',
  eventsViewMode: 'eventsViewMode',
  calendarView: 'calendarView',
  tabsOrder: 'tabsOrder',
  actorNumbers: 'actorNumbers',
  showNodesCoordinates: 'showNodesCoordinates',
};

// EVENTS HEADER CONTROLS
export const EVENTS_HEADER_CONTROLS = Object.freeze({
  search: 'search',
  calendar: 'calendar',
  split: 'split',
  addEvent: 'addEvent',
});

export const DEFAULT_EXPORT_GRAPH_SETTINGS = {
  actors: [],
  forms: [],
  transactions: false,
  attachments: true,
  users: false,
  processes: false,
};

export const ACCESS_RULES_ACTIONS = {
  create: 'create',
  update: 'update',
  delete: 'delete',
  existing: 'existing',
};

export const WIDGETS_NAMES = {
  lineDashboardWidget: 'LineDashboardWidget',
  scriptWidget: 'ScriptWidget',
};

export const CUSTOM_BAR_SCRIPT_PREFIX = 'customBarScript_';

export const STORAGE_KEY = {
  panelWidth: { default: 'panelWidth', event: 'eventPanelWidth' },
};

// Modifier for calculating label width
export const AVERAGE_CHAR_WIDTH_FACTOR = 0.6;

export const CURSOR = {
  POINTER: 'pointer',
  POINTER_DENIED: 'pointerDenied',
  POINTER_LOCK: 'pointerLock',
  DEFAULT: 'default',
  LASSO: 'lasso',
  STATE: 'state',
  GRABBING: 'grabbing',
};
