import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  Card,
  Space,
  Stack,
  Checkbox,
  Icon,
  Button,
  Label,
  ProgressBar,
  TextField,
  Popover,
  cr,
} from 'mw-style-react';
import { useIntl, useSearchList } from 'hooks';
import EmptyList from '@control-front-end/common/components/EmptyList';
import {
  GET_TEMPLATE_ACTORS,
  SEARCH_ACTORS,
} from '@control-front-end/common/constants/graphActors';
import mes from './intl';
import scss from './CheckboxSelectActors.scss';

/**
 * Popup with selectable actors list
 */
function ActorsPopup({
  formId,
  defaultListActors,
  activeIds,
  onClose,
  onCreate,
  onManage,
}) {
  const t = useIntl();

  const {
    list: actors,
    loading,
    searchParams: { query },
    makeSearch,
    onScroll,
  } = useSearchList({
    actionType: ({ query }) =>
      query ? SEARCH_ACTORS.REQUEST : GET_TEMPLATE_ACTORS.REQUEST,
    searchParams: {
      formId,
      params: { formId },
      orderBy: 'created_at',
      orderValue: 'ASC',
      query: '',
    },
    settings: { step: 10 },
    localState: true,
  });

  const handleSearch = ({ value }) => {
    makeSearch({ query: value.trim() });
  };

  const defaultList = useMemo(() => {
    return query
      ? defaultListActors.filter((i) =>
          i.title.toLowerCase().includes(query.toLowerCase())
        )
      : defaultListActors;
  }, [query, defaultListActors]);

  const renderActorsList = () => {
    const list = [...defaultList, ...actors];
    return cr(
      [
        list.length,
        list.map((i) => (
          <Checkbox
            key={i.id}
            id={i.id}
            value={activeIds.includes(i.id)}
            onChange={({ value }) => onManage(i, value)}
            visibility={i.disabled ? 'disabled' : 'visible'}
          >
            <Label value={i.title} overflow="cut" />
          </Checkbox>
        )),
      ],
      [true, <EmptyList size="small" objType="stream" />]
    );
  };

  return (
    <Card
      style={{ width: 216, height: 286 }}
      color={Card.COLOR.white}
      boxShadow={Card.BOX_SHADOW.light}
      borderRadius={Card.BORDER_RADIUS.large}
    >
      <Space fullHeight fullWidth>
        <Stack.V size={Stack.SIZE.small} fullHeight fullWidth>
          <Stack.H size="none" alignItems="center">
            <TextField
              leftIcon="search"
              bordered
              unspaced
              resettable
              value={query}
              placeholder={t(mes.search)}
              onChange={handleSearch}
              onReset={() => handleSearch({ value: '' })}
            />
            {onCreate ? (
              <Button
                type="quinary"
                size="small"
                icon={<Icon type="plus" color={Icon.COLOR.primary} />}
                onClick={() => {
                  onCreate();
                  onClose();
                }}
              />
            ) : null}
          </Stack.H>
          <Stack.V
            className={scss.list}
            fullWidth
            fullHeight
            size="none"
            onScroll={onScroll}
          >
            {cr(
              [
                loading && !actors.length,
                <ProgressBar className={scss.loader} />,
              ],
              [true, () => renderActorsList()]
            )}
          </Stack.V>
        </Stack.V>
      </Space>
    </Card>
  );
}

ActorsPopup.propTypes = {
  formId: PropTypes.number.isRequired,
  activeIds: PropTypes.array.isRequired,
  onClose: PropTypes.func,
  onManage: PropTypes.func,
  onCreate: PropTypes.func,
  defaultListActors: PropTypes.array,
};

/**
 * Button to show popup with a selectable actors list for streams, filters or folders
 */
function CheckboxSelectActors(props) {
  const {
    formId,
    icon,
    starredActors,
    defaultListActors,
    handleManage,
    handleCreate,
  } = props;

  return (
    <Popover
      topLevel
      anchors={{
        binding: Popover.ANCHOR.right_top,
        content: Popover.ANCHOR.right_top,
      }}
      content={({ onClose }) => (
        <ActorsPopup
          formId={formId}
          defaultListActors={defaultListActors}
          activeIds={starredActors.map((i) => i.id)}
          onClose={onClose}
          onCreate={handleCreate}
          onManage={handleManage}
        />
      )}
    >
      <Button type="quinary" size="xxsmall" icon={<Icon type={icon} />} />
    </Popover>
  );
}

CheckboxSelectActors.defaultProps = {
  icon: 'settings',
  defaultListActors: [],
};

CheckboxSelectActors.propTypes = {
  formId: PropTypes.number.isRequired,
  starredActors: PropTypes.array.isRequired,
  handleManage: PropTypes.func,
  handleCreate: PropTypes.func,
  icon: PropTypes.string,
};

export default CheckboxSelectActors;
