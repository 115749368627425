import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconPlus(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.fillStyle = theme.palette.primary;
  ctx.translate(-6, -6);
  ctx.beginPath();
  ctx.moveTo(18.078125, 8.75);
  ctx.bezierCurveTo(17.285156, 8.75, 16.640625, 9.402344, 16.640625, 10.207031);
  ctx.lineTo(16.640625, 16.042969);
  ctx.lineTo(10.878906, 16.042969);
  ctx.bezierCurveTo(10.085938, 16.042969, 9.441406, 16.695312, 9.441406, 17.5);
  ctx.bezierCurveTo(
    9.441406,
    18.304688,
    10.085938,
    18.957031,
    10.878906,
    18.957031
  );
  ctx.lineTo(16.640625, 18.957031);
  ctx.lineTo(16.640625, 24.792969);
  ctx.bezierCurveTo(16.640625, 25.597656, 17.285156, 26.25, 18.078125, 26.25);
  ctx.bezierCurveTo(18.875, 26.25, 19.519531, 25.597656, 19.519531, 24.792969);
  ctx.lineTo(19.519531, 18.957031);
  ctx.lineTo(25.28125, 18.957031);
  ctx.bezierCurveTo(26.074219, 18.957031, 26.71875, 18.304688, 26.71875, 17.5);
  ctx.bezierCurveTo(
    26.71875,
    16.695312,
    26.074219,
    16.042969,
    25.28125,
    16.042969
  );
  ctx.lineTo(19.519531, 16.042969);
  ctx.lineTo(19.519531, 10.207031);
  ctx.bezierCurveTo(19.519531, 9.402344, 18.875, 8.75, 18.078125, 8.75);
  ctx.closePath();
  ctx.moveTo(18.078125, 8.75);
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
}

export default SVGIconPlus;
