import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconAdd(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.fillStyle = theme.palette.primary;
  ctx.translate(-4, -6);
  ctx.beginPath();
  ctx.moveTo(0, 0);
  ctx.lineTo(16, 0);
  ctx.bezierCurveTo(16, 0, 16, 0, 16, 0);
  ctx.lineTo(16, 16);
  ctx.bezierCurveTo(16, 16, 16, 16, 16, 16);
  ctx.lineTo(0, 16);
  ctx.bezierCurveTo(0, 16, 0, 16, 0, 16);
  ctx.lineTo(0, 0);
  ctx.bezierCurveTo(0, 0, 0, 0, 0, 0);
  ctx.closePath();
  ctx.clip();
  ctx.beginPath();
  ctx.moveTo(13.6569, 13.6569);
  ctx.bezierCurveTo(10.5286, 16.7851, 5.4714, 16.7851, 2.34316, 13.6569);
  ctx.bezierCurveTo(-0.785083, 10.5286, -0.785084, 5.47139, 2.34316, 2.34314);
  ctx.bezierCurveTo(5.4714, -0.785096, 10.5286, -0.785096, 13.6569, 2.34315);
  ctx.bezierCurveTo(16.7851, 5.47139, 16.7851, 10.5286, 13.6569, 13.6569);
  ctx.closePath();
  ctx.moveTo(8.79763, 12.8592);
  ctx.lineTo(8.79763, 8.79762);
  ctx.lineTo(12.8592, 8.79762);
  ctx.lineTo(12.8592, 7.20238);
  ctx.lineTo(8.79763, 7.20238);
  ctx.lineTo(8.79763, 3.14076);
  ctx.lineTo(7.2024, 3.14076);
  ctx.lineTo(7.2024, 7.20238);
  ctx.lineTo(3.14077, 7.20238);
  ctx.lineTo(3.14077, 8.79762);
  ctx.lineTo(7.2024, 8.79762);
  ctx.lineTo(7.2024, 12.8592);
  ctx.lineTo(8.79763, 12.8592);
  ctx.closePath();
  ctx.fill();
  ctx.stroke();
  ctx.restore();
}

export default SVGIconAdd;
