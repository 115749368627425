import { call, put, select, takeEvery } from 'redux-saga/effects';
import {
  chunk,
  zipWith,
  pickBy,
  isEmpty,
  keyBy,
  mapValues,
  filter,
  unionBy,
  pick,
  property,
  mergeWith,
  groupBy,
} from 'lodash';
import { cr } from 'mw-style-react';

import {
  MANAGE_LAYER_ACTORS,
  GRAPH_DISCOVERY,
} from '@control-front-end/common/constants/graphLayers';
import { RequestStatus, BULK_ACTIONS_LIMIT } from 'constants';
import api from '@control-front-end/common/sagas/api';
import AppUtils from '@control-front-end/utils/utils';
import {
  keyByPosition,
  toCellCoord,
} from '@control-front-end/utils/modules/utilsCellCoords';
import {
  getGraphEls,
  getLayerById,
  getActiveLayer,
  makeGraphModels,
  sendReducerMsg,
  setActorsBalances,
} from './graphHelpers';

const ELEMENT_STATUS = {
  new: 'new',
  updated: 'updated',
  removed: 'removed',
};
const ELEMENT_TYPE = {
  node: 'node',
  edge: 'edge',
};

const prepareGraphItems = ({ nodes, edges }) => {
  return [
    ...Object.values(nodes).map((node) => ({
      id: `node_${node.laId}`,
      laId: node.laId,
      status: 'new',
      group: 'nodes',
      type: 'node',
      position: node.position,
      cellPosition: node.cellPosition,
      title: `Node: ${node.laId}`,
      layerSettings: { expand: false },
      isNonInteractive: true,
      isPlaceholder: true,
    })),
    ...Object.values(edges).map((edge) => ({
      id: `edge_${edge.laId}`,
      laId: edge.laId,
      type: 'edge',
      status: 'new',
      group: 'edges',
      edgeType: 'hierarchy',
      edgeId: edge.id,
      laIdSource: edge.laIdSource,
      laIdTarget: edge.laIdTarget,
      title: '',
      isNonInteractive: true,
      isPlaceholder: true,
    })),
  ];
};

const actorToNode = (actor) => {
  return {
    id: `node_${actor.laId}`,
    actorId: actor.actorId || actor.id,
    type: 'node',
    status: 'updated',
    data: {
      ...actor,
      id: `node_${actor.laId}`,
      actorId: actor.actorId || actor.id,
      type: 'node',
      isNonInteractive: actor.isNonInteractive || false,
      isPlaceholder: false,
    },
  };
};

const prepareEdgeToGraph = (edge) => {
  const graphEdge = {
    ...edge,
    id: `edge_${edge.id}`,
    edgeId: edge.edgeId || edge.id,
    status: 'updated',
    type: 'edge',
    isNonInteractive: edge.isNonInteractive || false,
    isPlaceholder: false,
  };
  return graphEdge;
};

const isRectWithinBounds = ({ rect, bounds, allowedShift = { h: 0, v: 0 } }) =>
  rect.x >= bounds.x - allowedShift.h &&
  rect.y >= bounds.y - allowedShift.v &&
  rect.x + rect.width <= bounds.x + bounds.width + allowedShift.h &&
  rect.y + rect.height <= bounds.y + bounds.height + allowedShift.v;

const isOldRect = ({ rect, rectsHistory }) => {
  return rectsHistory.some((i) =>
    isRectWithinBounds({
      rect,
      bounds: i,
      allowedShift: { h: i.width * 0.7, v: i.height * 0.7 },
    })
  );
};

const getExpandedRect = ({ x, y, width, height }) => {
  return {
    x: x - width,
    y: y - height,
    width: width * 3,
    height: height * 3,
  };
};

function* layerActorsStructure({ payload, callback }) {
  const { layerId, rect } = payload;

  const { result, data } = yield call(api, {
    method: 'get',
    url: `/graph_layers/area/${layerId}`,
    queryParams: rect
      ? {
          x1: rect.x,
          y1: rect.y,
          x2: rect.x + rect.width,
          y2: rect.y + rect.height,
        }
      : {},
  });
  if (result !== RequestStatus.SUCCESS) return { error: true };

  callback?.(data.data);
  return data.data;
}

export function* manageGraphElements({ payload, callback }) {
  const { createModelOnUpdate = true } = payload;
  const copyNodes = yield call(getGraphEls, 'nodes', payload.layerId);
  const copyEdges = yield call(getGraphEls, 'edges', payload.layerId);

  const layerModel = yield getLayerById(payload.layerId);

  const elements = mapValues(ELEMENT_TYPE, (type) =>
    mapValues(ELEMENT_STATUS, (status) =>
      keyBy(filter(payload.elements, { type, status }), 'id')
    )
  );

  // Find edges based on nodes that going to be removed
  const edgesToRemoveWithNodes = copyEdges.filter(
    ({ source, target, status, switchBox }) => {
      return (
        (elements.node.removed[source] || elements.node.removed[target]) &&
        status !== ELEMENT_STATUS.removed &&
        !switchBox
      );
    }
  );

  const newElements = yield call(makeGraphModels, {
    nodes: Object.values(elements.node.new),
    edges: Object.values(elements.edge.new),
    isTree: layerModel.type === 'tree',
  });

  const updatedElements = createModelOnUpdate
    ? yield call(makeGraphModels, {
        nodes: Object.values(elements.node.updated),
        edges: Object.values(elements.edge.updated),
        isTree: layerModel.type === 'tree',
        status: 'updated',
      })
    : {
        nodes: Object.values(elements.node.updated),
        edges: Object.values(elements.edge.updated),
      };
  const updatedElementsMap = mapValues(updatedElements, (items) =>
    keyBy(items, 'id')
  );

  yield sendReducerMsg({
    type: MANAGE_LAYER_ACTORS.SUCCESS,
    payload: {
      ...layerModel,
      nodes: unionBy(
        [
          ...copyNodes.map((node) => {
            const update =
              updatedElementsMap.nodes[node.id] ||
              elements.node.removed[node.id];
            if (!update) return node;
            return {
              ...node,
              ...update,
              data: { ...node.data, ...update.data },
            };
          }),
          ...newElements.nodes,
        ],
        'id'
      ),
      edges: [
        ...copyEdges.map((edge) => {
          const update = updatedElementsMap.edges[edge.id] ||
            elements.edge.removed[edge.id] ||
            edgesToRemoveWithNodes || { data: {} };
          return { ...edge, ...update, data: { ...edge.data, ...update.data } };
        }),
        ...newElements.edges,
      ],
    },
    layerId: layerModel.id,
  });

  callback?.();
}

function* getGraphDataByLaIds({ payload }) {
  const { layerId, nodes, edges, balanceParams, currencyParams } = payload;

  const queryParams = pickBy(balanceParams, Boolean);

  const chunks = chunk(
    zipWith(nodes, edges, (node, edge) => ({ node, edge })),
    BULK_ACTIONS_LIMIT
  );

  for (const chunk of chunks) {
    const { result, data } = yield call(api, {
      method: 'post',
      url: `/graph_layers/elements/${layerId}`,
      body: {
        nodes: chunk.map(({ node }) => node).filter(Boolean),
        edges: chunk.map(({ edge }) => edge).filter(Boolean),
      },
      queryParams,
    });
    if (result !== RequestStatus.SUCCESS) return;
    if (!isEmpty(queryParams)) {
      setActorsBalances({
        balances: data.data.nodes
          .filter(({ accessDenied }) => !accessDenied)
          .map(({ balance }) => balance),
        nodes: data.data.nodes,
        currencyParams,
        workWithData: false,
      });
    }

    const resultPayload = {
      nodes: data.data.nodes.map(actorToNode),
      edges: data.data.edges.map(prepareEdgeToGraph),
    };

    yield call(manageGraphElements, {
      payload: {
        elements: [...resultPayload.nodes, ...resultPayload.edges],
        layerId,
      },
    });
  }
}

function* loadGraphSector({ payload, callback }) {
  const { layerId, viewport, balanceParams } = payload;
  const graphDiscovery = yield select((state) => state.graphDiscovery);

  const layersGraphDiscovery = graphDiscovery[layerId] || {};

  const layerModel = yield getLayerById(layerId);
  const nodesMap = keyBy(layerModel.nodes, property('data.laId'));
  const edgesMap = keyBy(layerModel.edges, property('data.laId'));

  if (
    isOldRect({
      rect: viewport,
      rectsHistory: layersGraphDiscovery.rectsHistory || [],
    })
  ) {
    /**
     * If it's the same viewport but graph is empty - run callback anyway
     * to define that graph is initialized or for any other logic (case of tabs swithing)
     */
    callback?.();
    return;
  }

  const rectToFetch = getExpandedRect(viewport);

  const { nodes, edges, error } = yield call(layerActorsStructure, {
    payload: { layerId, rect: rectToFetch },
  });

  if (error) return;

  yield put({
    type: GRAPH_DISCOVERY.LOAD_SECTOR.SUCCESS,
    payload: {
      ...graphDiscovery,
      [layerId]: {
        ...layersGraphDiscovery,
        rectsHistory: [...(layersGraphDiscovery.rectsHistory || []), viewport],
        balanceParams: balanceParams || layersGraphDiscovery.balanceParams,
      },
    },
  });

  const newItems = {
    nodes: keyBy(
      nodes.filter(({ laId }) =>
        nodesMap[laId]
          ? nodesMap[laId].status === ELEMENT_STATUS.removed ||
            nodesMap[laId].data?.isPlaceholder
          : true
      ),
      'laId'
    ),
    edges: keyBy(
      edges.filter(({ laId }) =>
        edgesMap[laId]
          ? edgesMap[laId].status === ELEMENT_STATUS.removed ||
            edgesMap[laId].data?.isPlaceholder
          : true
      ),
      'laId'
    ),
  };

  // ✔ - Display nodes skeleton on the layer
  yield call(manageGraphElements, {
    payload: { elements: prepareGraphItems(newItems), layerId },
  });

  const nodesIds = Object.keys(newItems.nodes);

  // ✔ - Updates nodes skeleton with actors data
  yield call(getGraphDataByLaIds, {
    payload: {
      layerId,
      nodes: nodesIds,
      edges: Object.keys(newItems.edges),
      balanceParams: pick(balanceParams || layersGraphDiscovery.balanceParams, [
        'currencyId',
        'nameId',
        'from',
        'to',
        'currencyType',
        'currencyParams',
      ]),
    },
  });

  callback?.({ nodesIds });
}

const isTraceOverlapped = (target, nodes, newTraces) => {
  const targetCellPosition = keyByPosition(toCellCoord(target.position));
  return (
    newTraces.some(
      (trace) =>
        keyByPosition(toCellCoord(trace.position)) === targetCellPosition
    ) ||
    nodes.some((node) => {
      const nodeData = node.data();
      if (!nodeData || node.id() === target.id) return false;
      const nodePosition = node.position();
      const cellPosition = keyByPosition(toCellCoord(nodePosition));
      return !nodeData.isTrace && cellPosition === targetCellPosition;
    })
  );
};

const getShiftTraceNodes = ({ traces, maxStep, nodesMap }) => {
  return traces.map((i) => {
    const newStep = i.data.traceStep + 1;
    return {
      ...i,
      data: {
        ...i.data,
        traceStep: newStep,
        traceLevel: nodesMap[i.data.parentLaId]
          ? i.data.traceLevel + 1
          : i.data.traceLevel,
      },
      replaceAll: false,
      laId: i.data.laId,
      type: 'node',
      status:
        newStep > maxStep ? ELEMENT_STATUS.removed : ELEMENT_STATUS.updated,
    };
  });
};

const makeTraceNodes = (nodes) => {
  return nodes.map((node) => {
    const laId = `${node.laId}_${AppUtils.udid()}`;
    return {
      ...node,
      id: `node_ntrace_${laId}`,
      laId: `ntrace_${laId}`,
      parentLaId: node.laId,
      parentModel: node,
      group: 'nodes',
      type: 'node',
      title: node.title,
      traceLevel: 0,
      traceStep: 0,
      layerSettings: { ...(node.layerSettings || {}), expand: false },
      isNonInteractive: true,
      isPlaceholder: true,
      isTrace: true,
      locked: true,
      grabbable: false,
      autolock: true,
      selectable: false,
      status: ELEMENT_STATUS.new,
    };
  });
};

const makeTraceEdges = (traceNodes) => {
  return traceNodes.map((node) => {
    const {
      traceLevel = 0,
      traceStep = 0,
      parentLaId,
    } = node.status === ELEMENT_STATUS.new ? node : node.data;
    const laIdSource = traceLevel
      ? traceNodes.find((trace) => {
          const traceData =
            trace.status === ELEMENT_STATUS.new ? trace : trace.data;
          return (
            traceData.parentLaId === parentLaId &&
            traceData.traceLevel === traceLevel - 1
          );
        }).laId
      : parentLaId;
    const laId = `${parentLaId}_${AppUtils.udid()}`;
    return {
      id: `edge_etrace_${laId}`,
      laId: `etrace_${laId}`,
      type: 'edge',
      status: 'new',
      group: 'edges',
      edgeType: 'hierarchy',
      edgeId: `edge_${node.id}`,
      parentLaId,
      traceLevel,
      traceStep,
      laIdSource,
      laIdTarget: node.laId,
      title: '',
      isNonInteractive: true,
      isPlaceholder: true,
      isTrace: true,
      selectable: false,
    };
  });
};

/**
 * Clear not actual traces on layer
 * if passed parentLaId - clear all traces of specified node
 * if passed maxLevel - clear all traces that exceed max count
 * otherwise - clear all traces on layer
 */
function* clearTrace({ payload }) {
  const { parentLaIds, maxStep } = payload;
  const activeLayer = yield getActiveLayer();
  const { nodes, edges } = yield getLayerById(activeLayer.id);
  const tracesNodes = nodes.filter(({ data }) => data.isTrace);
  const traceEdges = edges.filter(({ data }) => data.isTrace);
  const traces = [...tracesNodes, ...traceEdges];
  const removedTraces = traces
    .filter(({ data }) => {
      if (Array.isArray(parentLaIds) && parentLaIds.length)
        return parentLaIds.includes(data.parentLaId);
      if (AppUtils.isNumeric(maxStep)) return data.traceStep > maxStep;
      return true;
    })
    .map((i) => ({
      ...i,
      replaceAll: false,
      status: ELEMENT_STATUS.removed,
      type: i.data.type,
    }));
  yield call(manageGraphElements, {
    payload: {
      elements: removedTraces,
      layerId: activeLayer.id,
      createModelOnUpdate: false,
    },
  });
}

function* makeTrace({ payload, callback }) {
  const activeLayer = yield getActiveLayer();
  if (!activeLayer) return;
  const layerModel = yield getLayerById(activeLayer.id);
  const { traceMaxCount = 1 } = yield select((state) => state.settings);
  if (!traceMaxCount) return;
  const maxStep = traceMaxCount - 1;
  const { elements, hidden = false } = payload;
  const { node: movedNodes = [] } = groupBy(elements, 'type');
  if (!movedNodes.length) return;
  const traces = layerModel.nodes.filter(({ data }) => data.isTrace);

  const newTraceNodes = hidden ? [] : makeTraceNodes(movedNodes);
  const nodesMap = keyBy(movedNodes, 'laId');
  const prevTraceNodes = getShiftTraceNodes({ nodesMap, traces, maxStep });
  const nodes = document.cy.nodes();
  prevTraceNodes.forEach((i) => {
    if (isTraceOverlapped(i, nodes, newTraceNodes)) {
      i.classes = ['hiddenLabel'];
    }
  });
  const nodesToManage = [...prevTraceNodes, ...newTraceNodes];
  const actualTraces = nodesToManage.filter(
    (i) => i.status !== ELEMENT_STATUS.removed
  );
  const newTraceEdges = keyBy(makeTraceEdges(actualTraces), 'laId');
  const prevTraceEdges = keyBy(
    layerModel.edges
      .filter(({ data }) => data.isTrace)
      .map((i) => ({
        ...i,
        replaceAll: false,
        status: ELEMENT_STATUS.removed,
        type: 'edge',
      })),
    'data.laId'
  );

  const edgesToManage = mergeWith(
    prevTraceEdges,
    newTraceEdges,
    (prevTraceEdge, newTraceEdge) => {
      return {
        ...(prevTraceEdge || {}),
        ...(newTraceEdge || {}),
        status: cr(
          [newTraceEdge && prevTraceEdge, ELEMENT_STATUS.updated],
          [newTraceEdge && !prevTraceEdge, ELEMENT_STATUS.new],
          [true, ELEMENT_STATUS.removed]
        ),
      };
    }
  );

  yield call(manageGraphElements, {
    payload: {
      elements: Object.values({ ...nodesToManage, ...edgesToManage }),
      layerId: activeLayer.id,
      createModelOnUpdate: false,
    },
  });

  callback?.();
}

function* loadWholeGraphSkeleton({ payload: { layerId }, callback }) {
  const graphDiscovery = yield select((state) => state.graphDiscovery);
  const layersGraphDiscovery = graphDiscovery[layerId] || {};

  if (layersGraphDiscovery.wholeSkeletonLoaded) return;

  const layerModel = yield getLayerById(layerId);
  const nodesMap = keyBy(layerModel.nodes, property('data.laId'));

  const edgesMap = keyBy(layerModel.edges, property('data.laId'));

  const { nodes, edges } = yield call(layerActorsStructure, {
    payload: { layerId },
  });

  const newItems = {
    nodes: keyBy(
      nodes.filter((i) =>
        nodesMap[i.laId]
          ? nodesMap[i.laId].status === ELEMENT_STATUS.removed
          : true
      ),
      'laId'
    ),
    edges: keyBy(
      edges.filter((i) =>
        edgesMap[i.laId]
          ? edgesMap[i.laId].status === ELEMENT_STATUS.removed
          : true
      ),
      'laId'
    ),
  };

  yield call(manageGraphElements, {
    payload: { elements: prepareGraphItems(newItems), layerId },
  });

  yield put({
    type: GRAPH_DISCOVERY.LOAD_WHOLE_SKELETON.SUCCESS,
    payload: {
      ...graphDiscovery,
      [layerId]: {
        ...layersGraphDiscovery,
        wholeSkeletonLoaded: true,
      },
    },
  });

  callback?.();
}

export function* refetchElements({ payload, callback }) {
  const copyNodes = yield call(getGraphEls, 'nodes');
  const copyEdges = yield call(getGraphEls, 'edges');

  const { layerId } = payload;
  const graphDiscovery = yield select((state) => state.graphDiscovery);

  const { rectsHistory = [], balanceParams } = graphDiscovery[layerId] || {};

  const lastViewport = rectsHistory[rectsHistory.length - 1];

  // Refetch only allowed if there is already fetched elements by some viewport
  if (!lastViewport) return;

  yield put({
    type: GRAPH_DISCOVERY.LOAD_SECTOR.SUCCESS,
    payload: {
      ...graphDiscovery,
      [layerId]: {
        rectsHistory: [],
        balanceParams,
      },
    },
  });

  const layerModel = yield getLayerById(payload.layerId);

  yield sendReducerMsg({
    type: MANAGE_LAYER_ACTORS.SUCCESS,
    payload: {
      ...layerModel,
      nodes: unionBy(
        [
          ...copyNodes.map((node) => ({
            ...node,
            status: ELEMENT_STATUS.removed,
          })),
        ],
        'id'
      ),
      edges: copyEdges.map((edge) => {
        return { ...edge, status: ELEMENT_STATUS.removed };
      }),
    },
    layerId: layerModel.id,
  });

  yield call(loadGraphSector, {
    payload: {
      layerId,
      viewport: lastViewport,
      balanceParams,
    },
  });

  callback?.();
}

function* expandNode({ position, expand, offset }) {
  const activeLayer = yield getActiveLayer();

  const { result, data } = yield call(api, {
    method: 'post',
    url: `/graph_layers/offset/${activeLayer.id}`,
    body: {
      position,
      offset: expand ? offset : mapValues(offset, (i) => -i),
    },
  });

  if (result !== RequestStatus.SUCCESS) return;

  const { nodes } = yield getLayerById(activeLayer.id);
  const nodesMap = keyBy(nodes, 'data.laId');

  yield call(manageGraphElements, {
    payload: {
      elements: data.data.reduce((acc, { id, position }) => {
        if (nodesMap[id]) {
          acc.push({
            ...nodesMap[id],
            data: {
              ...(nodesMap[id].data || {}),
              position,
              cellPosition: toCellCoord(position),
            },
            position,
            status: ELEMENT_STATUS.updated,
            type: 'node',
          });
        }
        return acc;
      }, []),
      layerId: activeLayer.id,
      createModelOnUpdate: false,
    },
  });
}

function* layerElements() {
  yield takeEvery(GRAPH_DISCOVERY.LOAD_SECTOR.REQUEST, loadGraphSector);
  yield takeEvery(
    GRAPH_DISCOVERY.LOAD_WHOLE_SKELETON.REQUEST,
    loadWholeGraphSkeleton
  );
  yield takeEvery(GRAPH_DISCOVERY.EXPAND_NODE.REQUEST, expandNode);
  yield takeEvery(GRAPH_DISCOVERY.MAKE_TRACE.REQUEST, makeTrace);
  yield takeEvery(GRAPH_DISCOVERY.CLEAR_TRACE.REQUEST, clearTrace);
}

export default layerElements;
