import React, {
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  useMemo,
  useCallback,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
  cr,
  forwardRef,
  Icon,
  Stack,
  Tab,
  TabItem,
  TextField,
  Utils,
} from 'mw-style-react';
import GridLayout from 'react-grid-layout';
import cn from 'classnames';
import { mapKeys } from 'lodash';
import { useResizeElement } from 'hooks';
import { ActorTabMenu } from 'components';
import TabsSelectPopup from '@control-front-end/common/components/TabsSelectPopup';
import CheckboxSelectActors from '@control-front-end/common/components/CheckboxSelectActors';
import { UPDATE_USER_SETTINGS } from 'constants';
import { UPDATE_ACTOR } from '@control-front-end/common/constants/graphActors';
import scss from '@control-front-end/common/styles/tabs.scss';
import AppUtils from '@control-front-end/utils/utils';

const ForwardTab = forwardRef(Tab);

const RESERVED_4K_WIDTH_FOR_GRID = 3840;
const DEFAULT_GRID_ITEM_WIDTH = 100;
const MAX_GRID_ITEM_WIDTH = 300;
const HANDLE_WIDTH = 12;
const SCROLL_TO_TAB_TIMEOUT = 500;
const MAX_TAB_LABEL_LENGTH = 30;

// Create layout of draggable items, applying saved order
// and make optional width for tab
const makeDraggableLayout = (items, order, sizes = {}) => {
  const freeOrderIndex = Object.keys(order).length;
  const layoutItems = items.map((tab) => {
    const w = (sizes[tab.id] || DEFAULT_GRID_ITEM_WIDTH) + HANDLE_WIDTH;
    return {
      i: tab.id,
      x: order ? order[tab.id] : freeOrderIndex,
      y: 0,
      h: 1,
      w: Math.min(Math.ceil(w), MAX_GRID_ITEM_WIDTH),
      static: tab.isSystem,
    };
  });
  const sortedItems = Utils.sort(layoutItems, 'x');
  const tabsLayout = [];
  let lastX = 0;
  const makeOptimalWidthTab = (item) => {
    tabsLayout.push({ ...item, x: lastX });
    // shift x position for next tab
    lastX += item.w;
  };
  sortedItems.forEach(makeOptimalWidthTab);
  return tabsLayout;
};

/**
 * Component of draggable actors tabs
 * @param tabs - list of tabs
 * @param defaultTabs - list of system predefined tabs
 * @param activeItem - active tab id
 * @param formId - form id of actors to check saved order settings
 * @param orderKey - custom key for tabs order storage in user settings
 * @param title - title of the tabs actors form
 * @param className - external class for container
 * @param renderDeps - list of dependencies, which affect tabs rendering
 * @param renderExtra - function to render extra component near the tab title
 * @param tabsRef - React Ref for tabs container
 * @param customizable - flag to allow select custom list of tabs display
 * @param draggable - flag to allow drag-n-drop tabs
 * @param actions - additional actions for tabs according to actor form
 * @param onSelect - handler for tab click
 * @param onClose - handler for tab close
 * @param onManage - handler for hide/show tab
 * @param onCreateClick - handler for tab create button '+' click
 * @returns {Element}
 * @constructor
 */
function ActorsTabs({
  tabsRef,
  tabs,
  defaultTabs,
  activeItem,
  orderKey: propsOrderKey,
  formId,
  title,
  className,
  renderDeps,
  renderExtra,
  customizable,
  draggable,
  actions,
  onSelect,
  onClose,
  onManage,
  onCreateClick,
}) {
  const dispatch = useDispatch();
  const containerRef = useRef();
  const tabsGhostRef = useRef();
  const accId = useSelector((state) => state.accounts.active);
  const tabsOrder = useSelector((state) => state.settings.tabsOrder);
  const [width, setWidth] = useState(0);
  const [layout, setLayout] = useState([]);
  const [dragging, setActiveDragging] = useState(false);
  const [tabSizes, setTabSizes] = useState(null);
  const [editableTab, setEditableTab] = useState(null);
  const orderKey = propsOrderKey || formId;

  const gridWidth = useMemo(
    () =>
      Math.max(RESERVED_4K_WIDTH_FOR_GRID, tabs.length * MAX_GRID_ITEM_WIDTH),
    [tabs.length]
  );

  // Check if some tabs are scrolled out of view
  const isTabsOverflow = useMemo(() => {
    if (!tabSizes || AppUtils.isUndefined(width)) return false;
    const realTabsWidth = Object.values(tabSizes).reduce(
      (sum, w) => sum + w,
      0
    );
    return realTabsWidth > width;
  }, [tabSizes, width]);

  useResizeElement(
    containerRef,
    ([entry]) => setWidth(entry.contentRect.width),
    renderDeps
  );

  // Measure real tab sizes for draggable grid items widths
  useLayoutEffect(() => {
    if (!tabsGhostRef.current) return;
    const tabItemElements = tabsGhostRef.current.firstChild.children;
    const realSizes = {};
    Array.from(tabItemElements).forEach((el) => {
      realSizes[el.id] = el.getBoundingClientRect().width;
    });
    setTabSizes(realSizes);
  }, [tabs]);

  useEffect(() => {
    if (!tabSizes) return;
    setLayout(makeDraggableLayout(tabs, tabsOrder[orderKey] || {}, tabSizes));
  }, [tabSizes]);

  useEffect(() => {
    setTimeout(
      () => AppUtils.scrollToElement(`tab_${activeItem}`),
      SCROLL_TO_TAB_TIMEOUT
    );
  }, [activeItem]);

  const handleRename = () => {
    const title = editableTab.title.trim();
    if (title.length) {
      dispatch({
        type: UPDATE_ACTOR.REQUEST,
        payload: { ...editableTab, title },
        callback: () => setEditableTab(null),
      });
    } else {
      setEditableTab(null);
    }
  };

  const saveUserSettings = (newLayout) => {
    const mapIds = mapKeys(tabs, 'id');
    const sortedItems = Utils.sort(newLayout, 'x').map((i, index) => ({
      ...i,
      x: index,
    }));
    // Set id as a key for easier access and existence check in order settings
    const settings = mapKeys(sortedItems, 'i');
    Object.keys(settings).forEach((id) => {
      if (mapIds[id]) {
        settings[id] = settings[id].x;
      } else {
        delete settings[id];
      }
    });
    dispatch({
      type: UPDATE_USER_SETTINGS.REQUEST,
      payload: {
        accId,
        tabsOrder: { ...tabsOrder, [orderKey]: settings },
      },
    });
  };

  const renderTab = (item, customId) => {
    const { id, title, counter, color, icon, isSystem } = item;
    const isActive = id === activeItem;
    const visibleLabel =
      title.length > MAX_TAB_LABEL_LENGTH
        ? `${title.slice(0, MAX_TAB_LABEL_LENGTH)}...`
        : title;
    const label =
      isActive && !AppUtils.isUndefined(counter)
        ? `${visibleLabel} (${counter})`
        : visibleLabel;
    return (
      <TabItem
        className={scss.streams__tabs__item}
        key={id}
        id={customId || `tab_${id}`}
        label={label}
        leftIcon={icon}
        value={id}
        color={color}
        activeItem={activeItem}
      >
        <Stack.H alignItems="center" size={Stack.SIZE.xsmall}>
          {renderExtra(item)}
          {!isSystem && tabsRef.current ? (
            <ActorTabMenu
              actor={item}
              tabNode={tabsRef.current.querySelector(`#tab_${id}`)}
              isActiveTab={id === activeItem}
              handleCloseTab={() => onClose(item)}
            />
          ) : null}
        </Stack.H>
      </TabItem>
    );
  };

  const renderDraggableTab = (item) => (
    <div
      key={item.id}
      className={scss.draggable__item}
      onDoubleClick={() => {
        const isEditable = item.privs?.modify && !item.isSystem;
        setEditableTab(isEditable ? item : null);
      }}
    >
      {cr([
        !item.isSystem,
        <Icon
          className={cn(scss.draggable__item__handle, 'drag')}
          type="drag"
        />,
      ])}
      {cr(
        [
          editableTab?.id === item.id,
          <TextField
            className={scss.renameField}
            value={editableTab?.title}
            length={255}
            size="small"
            autoSelect="mount"
            autoFocus
            bordered
            onBlur={handleRename}
            onKeyUp={(e) => {
              if (e.key !== 'Enter') return;
              handleRename();
            }}
            onChange={({ value }) => setEditableTab({ ...item, title: value })}
          />,
        ],
        [
          true,
          <div
            className={scss.draggable__item__wrap}
            onClick={() => onSelect(item)}
          >
            {renderTab(item)}
          </div>,
        ]
      )}
    </div>
  );

  // Tabs invisible simple tabs for measuring content size
  const renderTabsGhost = useCallback(
    () => (
      <ForwardTab
        ref={tabsGhostRef}
        className={cn(scss.streams__tabs, scss.ghost)}
        type="auto"
      >
        {tabs.map((item) => renderTab(item, item.id))}
      </ForwardTab>
    ),
    [tabs]
  );

  return (
    <Stack.H
      className={cn(className, scss.streams)}
      fullWidth
      size={Stack.SIZE.small}
      alignItems="center"
      justifyContent="spaceBetween"
    >
      <Stack.H
        forwardRef={containerRef}
        className={cn(scss.streams__tabs__wrap, {
          [scss.dragging]: dragging,
        })}
        alignItems="center"
        size="none"
        fullWidth
      >
        {cr(
          [
            draggable && layout.length,
            <GridLayout
              innerRef={tabsRef}
              className={scss.draggable}
              layout={layout}
              rowHeight={36}
              width={gridWidth}
              cols={gridWidth}
              compactType="horizontal"
              draggable
              isBounded
              useCSSTransforms
              margin={[0, 0]}
              containerPadding={[0, 0]}
              draggableHandle=".drag"
              onDragStart={() => setActiveDragging(true)}
              onDragStop={(newLayout) => {
                setLayout(newLayout);
                saveUserSettings(newLayout);
                setActiveDragging(false);
              }}
            >
              {tabs.map(renderDraggableTab)}
            </GridLayout>,
          ],
          [
            true,
            <ForwardTab
              ref={tabsRef}
              className={scss.streams__tabs}
              value={activeItem}
              type="auto"
              onChange={onSelect}
            >
              {tabs.map(renderTab)}
            </ForwardTab>,
          ]
        )}
        {cr([draggable, renderTabsGhost])}
      </Stack.H>
      {cr([
        isTabsOverflow,
        () => (
          <TabsSelectPopup
            title={title}
            tabs={tabs}
            activeItem={activeItem}
            onSelect={onSelect}
          />
        ),
      ])}
      <Stack
        className={scss.streams__controls}
        horizontal
        alignItems="center"
        size={Stack.SIZE.small}
      >
        {cr([
          onCreateClick,
          () => (
            <div onClick={onCreateClick}>
              <Icon type="plus" />
            </div>
          ),
        ])}
        {actions}
        {cr([
          customizable && formId,
          () => (
            <CheckboxSelectActors
              formId={formId}
              defaultListActors={defaultTabs}
              starredActors={tabs}
              handleManage={onManage}
            />
          ),
        ])}
      </Stack>
    </Stack.H>
  );
}

ActorsTabs.defaultProps = {
  defaultTabs: [],
  draggable: false,
  customizable: false,
  renderExtra: () => null,
  renderDeps: [],
  actions: null,
  title: 'Tabs',
};

ActorsTabs.propTypes = {
  tabs: PropTypes.array.isRequired,
  defaultTabs: PropTypes.array,
  formId: PropTypes.number,
  orderKey: PropTypes.string,
  title: PropTypes.string.isRequired,
  activeItem: PropTypes.string.isRequired,
  tabsRef: PropTypes.object.isRequired,
  renderDeps: PropTypes.array,
  renderExtra: PropTypes.func,
  draggable: PropTypes.bool,
  customizable: PropTypes.bool,
  actions: PropTypes.node,
  onManage: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  onCreateClick: PropTypes.func,
};

export default ActorsTabs;
