import { CorezoidLightTheme as theme } from 'mw-style-react';

function SVGIconAccess(ctx) {
  ctx.save();
  ctx.strokeStyle = 'rgba(0,0,0,0)';
  ctx.fillStyle = theme.palette.primary;
  ctx.translate(-6, -6);
  ctx.beginPath();
  ctx.moveTo(19.203125, 11.914062);
  ctx.bezierCurveTo(
    20.441406,
    11.589844,
    21.359375,
    10.464844,
    21.359375,
    9.125
  );
  ctx.bezierCurveTo(
    21.359375,
    7.53125,
    20.070312,
    6.242188,
    18.480469,
    6.242188
  );
  ctx.bezierCurveTo(16.890625, 6.242188, 15.597656, 7.53125, 15.597656, 9.125);
  ctx.bezierCurveTo(
    15.597656,
    10.464844,
    16.519531,
    11.59375,
    17.761719,
    11.914062
  );
  ctx.lineTo(17.761719, 16.414062);
  ctx.bezierCurveTo(
    17.054688,
    16.59375,
    16.449219,
    17.039062,
    16.0625,
    17.636719
  );
  ctx.lineTo(12.710938, 16.523438);
  ctx.bezierCurveTo(
    12.71875,
    16.457031,
    12.71875,
    16.390625,
    12.71875,
    16.324219
  );
  ctx.bezierCurveTo(
    12.71875,
    14.734375,
    11.429688,
    13.445312,
    9.839844,
    13.445312
  );
  ctx.bezierCurveTo(8.25, 13.445312, 6.960938, 14.734375, 6.960938, 16.324219);
  ctx.bezierCurveTo(6.960938, 17.914062, 8.25, 19.203125, 9.839844, 19.203125);
  ctx.bezierCurveTo(
    10.851562,
    19.203125,
    11.746094,
    18.679688,
    12.257812,
    17.886719
  );
  ctx.lineTo(15.605469, 19.003906);
  ctx.bezierCurveTo(
    15.601562,
    19.070312,
    15.597656,
    19.136719,
    15.597656,
    19.203125
  );
  ctx.bezierCurveTo(
    15.597656,
    19.851562,
    15.8125,
    20.453125,
    16.175781,
    20.933594
  );
  ctx.lineTo(13.902344, 23.777344);
  ctx.bezierCurveTo(
    13.542969,
    23.613281,
    13.140625,
    23.523438,
    12.71875,
    23.523438
  );
  ctx.bezierCurveTo(
    11.128906,
    23.523438,
    9.839844,
    24.8125,
    9.839844,
    26.402344
  );
  ctx.bezierCurveTo(
    9.839844,
    27.996094,
    11.128906,
    29.285156,
    12.71875,
    29.285156
  );
  ctx.bezierCurveTo(
    14.308594,
    29.285156,
    15.597656,
    27.996094,
    15.597656,
    26.402344
  );
  ctx.bezierCurveTo(
    15.597656,
    25.757812,
    15.386719,
    25.160156,
    15.023438,
    24.675781
  );
  ctx.lineTo(17.300781, 21.832031);
  ctx.bezierCurveTo(
    17.660156,
    21.992188,
    18.058594,
    22.082031,
    18.480469,
    22.082031
  );
  ctx.bezierCurveTo(
    18.902344,
    22.082031,
    19.300781,
    21.992188,
    19.660156,
    21.832031
  );
  ctx.lineTo(21.9375, 24.675781);
  ctx.bezierCurveTo(
    21.574219,
    25.15625,
    21.359375,
    25.753906,
    21.359375,
    26.402344
  );
  ctx.bezierCurveTo(
    21.359375,
    27.996094,
    22.648438,
    29.285156,
    24.238281,
    29.285156
  );
  ctx.bezierCurveTo(
    25.832031,
    29.285156,
    27.121094,
    27.996094,
    27.121094,
    26.402344
  );
  ctx.bezierCurveTo(
    27.121094,
    24.8125,
    25.832031,
    23.523438,
    24.238281,
    23.523438
  );
  ctx.bezierCurveTo(
    23.820312,
    23.523438,
    23.421875,
    23.613281,
    23.0625,
    23.773438
  );
  ctx.lineTo(20.785156, 20.929688);
  ctx.bezierCurveTo(
    21.144531,
    20.449219,
    21.359375,
    19.851562,
    21.359375,
    19.203125
  );
  ctx.bezierCurveTo(
    21.359375,
    19.136719,
    21.355469,
    19.070312,
    21.351562,
    19.003906
  );
  ctx.lineTo(24.703125, 17.890625);
  ctx.bezierCurveTo(
    25.214844,
    18.679688,
    26.105469,
    19.203125,
    27.121094,
    19.203125
  );
  ctx.bezierCurveTo(28.710938, 19.203125, 30, 17.914062, 30, 16.324219);
  ctx.bezierCurveTo(30, 14.734375, 28.710938, 13.445312, 27.121094, 13.445312);
  ctx.bezierCurveTo(
    25.527344,
    13.445312,
    24.238281,
    14.734375,
    24.238281,
    16.324219
  );
  ctx.bezierCurveTo(
    24.238281,
    16.390625,
    24.242188,
    16.457031,
    24.246094,
    16.523438
  );
  ctx.lineTo(20.898438, 17.640625);
  ctx.bezierCurveTo(
    20.511719,
    17.042969,
    19.910156,
    16.597656,
    19.203125,
    16.414062
  );
  ctx.closePath();
  ctx.moveTo(19.203125, 11.914062);
  ctx.fill('evenodd');
  ctx.stroke();
  ctx.restore();
}

export default SVGIconAccess;
