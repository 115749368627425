import { CorezoidLightTheme as theme } from 'mw-style-react';
import {
  NODE_TYPE,
  NODE_Z_INDEX,
  TRACE_OPACITY,
} from '@control-front-end/common/constants/graphActors';
import AppUtils from '@control-front-end/utils/utils';

const calcOpacity = (opacity, level = 0) =>
  opacity.default - level * opacity.step;

const textStyles = {
  color: theme.palette.black,
  'font-family': 'Open Sans,sans-serif',
};

const LABEL_MAX_WIDTH = 200;

const pictureNode = (field) => ({
  height: (ele) => (ele.data(field) ? `${ele.data(field).height}px` : 0),
  width: (ele) => (ele.data(field) ? `${ele.data(field).width}px` : 0),
  shape: 'polygon',
  'background-width': (ele) =>
    ele.data(field) ? `${ele.data(field).width}px` : 0,
  'background-height': (ele) =>
    ele.data(field) ? `${ele.data(field).height}px` : 0,
  'background-color': 'transparent',
  'background-opacity': 0,
  'outline-width': 0,
  'border-color': (ele) => ele.data('color') || '#B0B6BF',
  'background-image': (ele) => (ele.data(field) ? ele.data(field).img : 'none'),
  'border-width': (ele) =>
    ele.data(field) && ele.data(field).border ? ele.data(field).border : 0,
  'overlay-padding': '6px',
  'font-size': '14px',
  ...textStyles,
});

export default [
  {
    selector: 'node',
    style: {
      height: '48px',
      width: '48px',
      shape: 'ellipse',
      opacity: 1,
      'background-color': (ele) => ele.data('color') || theme.palette.white,
      'border-color': theme.palette.white,
      'border-width': '1px',
      'border-position': 'inside',
      'outline-style': 'solid',
      'outline-color': theme.palette.gray,
      'outline-width': 1,
      'background-image': (ele) => ele.data('pictureUrl') || 'none',
      'background-image-crossorigin': 'anonymous',
      'background-fit': 'cover',
      'background-clip': 'node',
      'overlay-padding': '6px',
      'font-size': '14px',
      'z-index': NODE_Z_INDEX[NODE_TYPE.default],
      'z-index-compare': 'manual',
      ...textStyles,
    },
  },
  {
    selector: 'node.switchBox',
    style: {
      shape: 'roundrectangle',
      padding: '4px',
      'background-color': '#DDE0E5',
      'outline-color': '#DDE0E5',
    },
  },
  {
    selector: 'node.switchBoxItem',
    style: {
      height: '16px',
      width: '16px',
      'outline-color': theme.palette.white,
    },
  },
  {
    selector: 'node[gradient]',
    style: {
      'background-fill': (ele) => ele.data('gradient').type,
      'background-gradient-stop-colors': (ele) => ele.data('gradient').colors,
    },
  },
  {
    selector: 'node:selected',
    style: {
      ...textStyles,
      'outline-color': theme.palette.primary,
    },
  },
  {
    selector: 'edge',
    style: {
      'z-index': NODE_Z_INDEX[NODE_TYPE.edge],
    },
  },
  {
    selector: 'edge[!transfers]',
    style: {
      width: '2px',
      'curve-style': 'unbundled-bezier',
      'target-arrow-shape': (ele) => ele.data('targetArrow') || 'none',
      'line-color': theme.palette.gray,
      'target-arrow-color': theme.palette.gray,
      'control-point-weights': '0.25 0.75',
      'control-point-distances': (ele) =>
        ele.data('controlPointDistances') || '0 0',
    },
  },
  {
    selector: 'edge[name]',
    style: {
      label: (ele) =>
        `${ele.data('name') || ''} ${
          ele.data('actorNumber') ? `№${ele.data('actorNumber')}` : ''
        }`,
      'font-size': '12px',
      ...textStyles,
      'text-rotation': 'autorotate',
      'text-margin-x': -16,
      'text-margin-y': -16,
      'text-wrap': 'ellipsis',
      'text-max-width': LABEL_MAX_WIDTH,
    },
  },
  {
    selector: 'edge[count]',
    style: {
      'font-size': '14px',
      'text-background-color': theme.palette.white,
      'text-background-opacity': 0.8,
      'text-background-shape': 'round-rectangle',
      'text-background-padding': 4,
    },
  },
  {
    selector: 'edge[?formField]',
    style: {
      'line-color': '#fdab00',
    },
  },
  {
    selector: 'edge:selected',
    style: {
      'line-color': theme.palette.primary,
      'target-arrow-color': theme.palette.primary,
      'text-wrap': 'ellipsis',
      'text-max-width': LABEL_MAX_WIDTH,
    },
  },
  {
    selector: 'edge.transfers',
    style: {
      'curve-style': 'bezier',
      'target-arrow-shape': (ele) => ele.data('targetArrow') || 'none',
      color: '#ffffff',
      'text-background-color': '#1EC00A',
      'line-color': '#1EC00A',
      'target-arrow-color': '#1EC00A',
      'text-background-shape': 'roundrectangle',
      'text-background-opacity': 1,
      'text-background-padding': 4,
      'text-margin-x': 0,
      'text-margin-y': 0,
      'font-weight': 600,
      width: (ele) => (ele.data('width') ? `${ele.data('width')}px` : '1px'),
    },
  },
  {
    selector: 'core',
    style: {
      'active-bg-size': 0,
      'selection-box-color': theme.palette.primary,
      'selection-box-opacity': 0.2,
      'selection-box-border-color': theme.palette.primary,
    },
  },

  // some style for the extension

  {
    selector: '.eh-hover',
    style: {
      'outline-color': theme.palette.primary,
    },
  },

  {
    selector: '.eh-source',
    style: {
      'outline-width': 2,
      'outline-color': theme.palette.primary,
    },
  },
  {
    selector: '.eh-target',
    style: {
      'outline-width': 2,
      'outline-color': theme.palette.primary,
    },
  },
  {
    selector: '.eh-preview, .eh-ghost-edge',
    style: {
      'line-color': theme.palette.primary,
      'target-arrow-shape': (ele) => ele.data('targetArrow') || 'none',
    },
  },

  {
    selector: '.eh-reverse-arrow',
    style: {
      'source-arrow-color': theme.palette.primary,
      'source-arrow-shape': 'triangle',
      'target-arrow-shape': 'none',
    },
  },

  {
    selector: '.eh-arrow',
    style: {
      'target-arrow-color': theme.palette.primary,
      'target-arrow-shape': 'triangle',
      'source-arrow-shape': 'none',
    },
  },

  {
    selector: '.eh-preview.eh-arrow',
    style: {
      'source-arrow-shape': 'none',
      'target-arrow-shape': 'triangle',
      'target-arrow-color': theme.palette.primary,
    },
  },

  {
    selector: '.eh-preview.eh-reverse-arrow',
    style: {
      'source-arrow-shape': 'triangle',
      'target-arrow-shape': 'none',
      'source-arrow-color': theme.palette.primary,
    },
  },
  {
    selector: '.eh-ghost-edge.eh-preview-active',
    style: {
      opacity: 0,
    },
  },
  {
    selector: '.semitransp',
    style: {
      opacity: 0.3,
    },
  },
  {
    selector: 'node[?accessDenied]',
    style: {
      'outline-color': '#DEE3E8',
      'background-color': theme.palette.white,
    },
  },
  {
    selector: 'node[?layerSettings.expand][!layerSettings.isCardView]',
    style: {
      opacity: 0,
    },
  },

  {
    selector: '.hide',
    style: {
      display: 'none',
    },
  },
  {
    selector: 'node[?areaPicture]',
    style: pictureNode('areaPicture'),
  },
  {
    selector: '.areaPicture.eh-target',
    style: {
      'background-image-opacity': '0.5',
    },
  },
  {
    selector: '.areaPicture.eh-target.forbidden',
    style: {
      'background-image-opacity': 1,
      'border-color': theme.palette.red,
      'border-width': 1,
    },
  },
  {
    selector: 'edge.areaPicture:unselected',
    style: {
      'line-color': '#6E7F96',
      'target-arrow-color': '#6E7F96',
    },
  },
  {
    selector: 'node.areaPicture',
    style: {
      'z-index': NODE_Z_INDEX[NODE_TYPE.layerArea],
    },
  },
  {
    selector: 'node.stateMarkup',
    style: {
      'z-index': NODE_Z_INDEX[NODE_TYPE.state],
      'overlay-padding': 0,
    },
  },
  {
    selector: 'node.stateMarkup:selected',
    style: {
      'border-color': theme.palette.primary,
      'border-width': '1px',
    },
  },
  {
    selector: 'node.dragOver',
    style: {
      'outline-color': theme.palette.primary,
      'outline-width': '1px',
      'outline-style': 'dashed',
      'background-blacken': '0.1',
    },
  },
  {
    selector: 'node.pictureObject',
    style: {
      ...pictureNode('copyPictureObject'),
      'z-index': NODE_Z_INDEX[NODE_TYPE.picture],
    },
  },
  {
    selector: 'node.pictureObject[?layerSettings.opacity]',
    style: {
      opacity: (ele) => ele.data('layerSettings').opacity,
    },
  },
  {
    selector: 'node[?layerSettings.zIndex]',
    style: {
      'z-index': (ele) => ele.data('layerSettings').zIndex,
    },
  },
  {
    selector: 'node.pictureObject[?layerSettings.width]',
    style: {
      width: (ele) => `${ele.data('layerSettings').width}px`,
      height: (ele) => `${ele.data('layerSettings').height}px`,
      'background-width': (ele) => `${ele.data('layerSettings').width}px`,
      'background-height': (ele) => `${ele.data('layerSettings').height}px`,
    },
  },
  {
    selector: '.forbidden',
    style: {
      'outline-color': theme.palette.red,
      'outline-width': 2,
    },
  },
  {
    selector: '.cxtmenu-disabled',
    style: {
      display: 'none',
    },
  },
  {
    selector: '.activeFormNode',
    style: {
      'outline-color': theme.palette.primary,
      'outline-width': 4,
    },
  },
  {
    selector: 'node[formType="system"]',
    style: {
      shape: 'roundrectangle',
    },
  },
  {
    selector: 'node.stateMarkup',
    style: {
      shape: 'polygon',
    },
  },
  {
    selector: 'node[?isTrace]',
    style: {
      'background-opacity': (ele) =>
        calcOpacity(TRACE_OPACITY.background, ele.data('traceLevel')),
      'background-image-opacity': (ele) =>
        calcOpacity(TRACE_OPACITY.image, ele.data('traceLevel')),
      'outline-color': theme.palette.gray,
      'outline-style': 'dashed',
      'outline-opacity': (ele) =>
        calcOpacity(TRACE_OPACITY.border, ele.data('traceLevel')),
      'outline-width': '1px',
      'z-index': NODE_Z_INDEX[NODE_TYPE.trace],
    },
  },
  {
    selector: 'edge[?isTrace]',
    style: {
      'line-style': 'dashed',
      'line-color': theme.palette.gray,
      'line-opacity': (ele) =>
        calcOpacity(TRACE_OPACITY.border, ele.data('traceLevel')),
      width: 1,
    },
  },
  // label
  {
    selector: 'node[title]',
    style: {
      label: (ele) => ele.data('title'),
      'font-weight': 600,
      'text-background-opacity': 0.5,
      'text-background-color': 'hsla(0, 0%, 96%)',
      'text-background-padding': 2,
      'text-valign': (ele) =>
        ele.data('pictureObject') || ele.data('areaPicture') ? 'top' : 'center',
      'text-halign': (ele) =>
        ele.data('pictureObject') || ele.data('areaPicture') ? 'left' : 'right',
      'text-margin-x': (ele) =>
        ele.data('pictureObject') || ele.data('areaPicture')
          ? Math.min(
              AppUtils.measureTextWidth({
                text: ele.data('title'),
                fontWeight: 600,
              }),
              LABEL_MAX_WIDTH
            )
          : 10,
      'text-margin-y': (ele) =>
        ele.data('pictureObject') || ele.data('areaPicture') ? -16 : 0,
      'text-wrap': 'ellipsis',
      'text-max-width': LABEL_MAX_WIDTH,
      'text-opacity': (ele) =>
        ele.data('isTrace')
          ? calcOpacity(TRACE_OPACITY.border, ele.data('traceLevel'))
          : 1,
    },
  },
  {
    selector: '.hiddenLabel',
    style: {
      'text-opacity': 0,
    },
  },
  {
    selector: 'node.dashedBorder',
    style: {
      'outline-style': 'dashed',
      'outline-color': theme.palette.primary,
      'outline-width': 1,
    },
  },
  {
    selector: 'node.primaryBorder',
    style: {
      'outline-color': theme.palette.primary,
    },
  },
];
